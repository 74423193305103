import { clone } from 'lodash';
import { MENU_ACTIONS } from './Menu.action';

const defaultMenuState = {
  displayMenu: false,
};

// tslint:disable-next-line: variable-name
export const Menu = (
  state = clone(defaultMenuState),
  action: any,
) => {
  switch (action.type) {
    case MENU_ACTIONS.TOGGLE_MENU:
      return {
        displayMenu: !state.displayMenu,
      };
    default:
      return state;
  }
};
