import * as React from 'react';
import { NavLink } from 'react-router-dom';
import getModuleLanguage from '../Dictionaries/Dictionaries';
let dictionary;
import style from './Start.module.less';
import dash_contact from '../../assets/images/dash_contact@2x.png';
import dash_faq from '../../assets/images/dash_faq@2x.png';
import dash_address from '../../assets/images/dash_address@2x.png';
import abc from '../../assets/images/abc@2x.png';
import map from '../../assets/images/map@2x.png';
import refresh from '../../assets/images/refresh@2x.png';
import { mapStateToProps, mapDispatchToProps } from './Start.connect';
import { connect } from 'react-redux';
import { IStartProps } from './Start.interface';

class Start extends React.Component<IStartProps> {
  public constructor(props: IStartProps) {
    super(props);
    this.loadScript();
  }

  public render() {
    dictionary = getModuleLanguage('start');
    const { isLoading, addresses } = this.props;
    const city = 'Szeged'; // to be replaced
    return (
      <section className={style.start}>
        {isLoading &&
          <div className="loading loadingAnimation">
            <img src={refresh} />
          </div>
        }
        {!isLoading && (addresses && !addresses.length) &&
          <NavLink
            className={`${style.iconwithtext} ${style.noaddress} d2grey-text`}
            to={'addresses'}
          >
            <img src={dash_address} />
            {dictionary.noAdresses}
          </NavLink>
        }
        <article className="container gap24">
          <h3 dangerouslySetInnerHTML={{ __html: dictionary.selectCity.replace('%city%', city) }} />
          <div className={style.button_holder}>
            <div className={style.big_button}>
              <NavLink to="search" >
                <img src={abc} />
              </NavLink>
            </div>
            <div className={`${style.big_button} ${style.smap}`}>
              <NavLink to="map" >
                <img src={map} />
              </NavLink>
            </div>
          </div>
        </article>
        {!isLoading &&
          <NavLink className={`${style.iconwithtext} d2grey-text`} to="faq">
            <img src={dash_faq} />
            {dictionary.howItWorks}
          </NavLink>
        }
        {false &&
          <NavLink className={`${style.iconwithtext} d2grey-text`} to="faq">
            <img src={dash_contact} />
            {dictionary.suggestPlace}
          </NavLink>
        }
      </section>
    );
  }

  private loadScript = () => {
    if (!document.querySelectorAll('#gmap_src').length) {
      const script = document.createElement('script');
      script.id = 'gmap_src';
      script.async = true;
      script.defer = true;
      script.src =
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyADhAkaGeaUXaO3qx10_lhPeKpi6S0BKHU';
      document.body.appendChild(script);
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Start);
