import url_config from '../../util/url_config';
import { ICartFields } from './Cart.interface';

export const CART_ACTIONS = {
  ADD_TO_CART: '[CART] ADD_TO_CART',
  REMOVE_FROM_CART: '[CART] REMOVE_FROM_CART',
  CLOSE_ORDER: '[CART] CLOSE_ORDER',
  VALIDATE_CUPON: '[CART] VALIDATE_CUPON',
  UPDATE_CART: '[CART] UPDATE_CART',
  SUCCESS: {
    CLOSE_ORDER: '[CART] CLOSE_ORDER_SUCCESS',
    VALIDATE_CUPON: '[CART] VALIDATE_CUPON_SUCCESS',
  },
  FAIL: {
    CLOSE_ORDER: '[CART] CLOSE_ORDER_FAIL',
    VALIDATE_CUPON: '[CART] VALIDATE_CUPON_FAIL',
  },
};

export const removeFromCart = (index: number) => ({
  index,
  type: CART_ACTIONS.REMOVE_FROM_CART,
});

export const updateCart = (field: string, value: any) => ({
  field,
  value,
  type: CART_ACTIONS.UPDATE_CART,
});

export const closeOrder = (data: ICartFields) => {
  return {
    payload: {
      client: url_config.DEFAULT_CLIENT,
      request: {
        method: 'GET',
        url: url_config.GLOBAL.GET_INIT_DATA,
        params: {
          action: 'op_close_order',
          ...data,
        },
      },
    },
    type: CART_ACTIONS.CLOSE_ORDER,
  };
};

export const validateCupon = (code: string, blogid: string) => {
  return {
    payload: {
      client: url_config.DEFAULT_CLIENT,
      request: {
        method: 'GET',
        url: url_config.GLOBAL.GET_INIT_DATA,
        params: {
          code,
          blogid,
          action: 'op_validate_cupon',
        },
      },
    },
    type: CART_ACTIONS.VALIDATE_CUPON,
  };
};
