import { getFaq } from '../FAQ/FAQ.action';
import { getInitData } from './App.action';

export const mapStateToProps = ({ App, Menu, Login }: any) => ({
  isLoading: App.isLoading,
  version: App.version,
  displayMenu: Menu.displayMenu,
  banned: Login.userData.banned || '0',
});

export const mapDispatchToProps = (dispatch: any) => ({
  action: {
    getFaq: () => dispatch(getFaq()),
    getInitData: () => dispatch(getInitData()),
  },
});
