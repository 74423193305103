import { clone } from 'lodash';
import { SETTINGS_ACTIONS } from './Settings.action';

const defaultAppState: any = {
  isLoading: true,
  orders: [],
};

// tslint:disable-next-line: variable-name
export const Settings = (
  state = clone(defaultAppState),
  action: any,
) => {
  switch (action.type) {
    case SETTINGS_ACTIONS.UPDATE_USER_SETTINGS:
      return {
        ...state,
        isLoading: true,
      };
    case SETTINGS_ACTIONS.FAIL.UPDATE_USER_SETTINGS:
      return {
        ...defaultAppState,
        isLoading: false,
      };
    case SETTINGS_ACTIONS.SUCCESS.UPDATE_USER_SETTINGS:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
