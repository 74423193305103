import * as React from 'react';
import { mapStateToProps, mapDispatchToProps } from './History.connect';
import { connect } from 'react-redux';
import style from './History.module.less';
import { IHistoryProps, IHistoryState } from './History.interface';
import ReactSwipe from 'react-swipe';
import Slide1 from './Slides/Slide1';
import Slide2 from './Slides/Slide2';
import Slide3 from './Slides/Slide3';
import Slide4 from './Slides/Slide4';
import Slide5 from './Slides/Slide5';

class History extends React.Component<IHistoryProps, IHistoryState> {
  constructor(props: IHistoryProps) {
    super(props);
    this.state = {
      currentSlide: 0,
    };
  }

  public render() {
    if (!Object.keys(this.props.history).length) {
      return null;
    }
    const slideProps = {
      ...this.props,
    };
    return (
      <section className={style.history}>
        <div className={style.slides}>
          <ReactSwipe
            swipeOptions={{
              continuous: false,
              speed: 500,
              transitionEnd: (index: number) => this.setState({ currentSlide: index }),
              startSlide: this.state.currentSlide,
            }}
            childCount={5}
          >
            <Slide1 {...slideProps} />
            <Slide2 {...slideProps} />
            <Slide3 {...slideProps} />
            <Slide4 {...slideProps} />
            <Slide5 {...slideProps} />
          </ReactSwipe>
          <ul className={style.dots}>
            {
              // tslint:disable-next-line: variable-name
              Array(5).fill(0).map((_item: number, index: number) => (
                <li
                  className={index === this.state.currentSlide ? style.active : ''}
                  key={`slide-${index}`}
                  onClick={() => this.setState({ currentSlide: index })}
                />
              ))
            }
          </ul>
        </div>
      </section >
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(History);
