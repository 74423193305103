import url_config from '../../util/url_config';

export const SETTINGS_ACTIONS = {
  UPDATE_USER_SETTINGS: '[SETTINGS] UPDATE_USER_SETTINGS',
  SUCCESS: {
    UPDATE_USER_SETTINGS: '[SETTINGS] UPDATE_USER_SETTINGS_SUCCESS',
  },
  FAIL: {
    UPDATE_USER_SETTINGS: '[SETTINGS] UPDATE_USER_SETTINGS_FAIL',
  },
};

export const saveProfile = (profile: any) => {
  const data = new FormData();
  Object.keys(profile).forEach((item) => {
    data.append(`profile[${item}]`, profile[item]);
  });
  data.append('action', 'op_update_user');
  return {
    payload: {
      client: url_config.DEFAULT_CLIENT,
      request: {
        data,
        method: 'POST',
        url: url_config.GLOBAL.GET_INIT_DATA,
      },
    },
    type: SETTINGS_ACTIONS.UPDATE_USER_SETTINGS,
  };

};
