import 'core-js';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter as Router, withRouter, Redirect } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

import './styles/index.less';

import App from './components/App/App';
import Root from './Root';

import { NotFound } from './components/404/404';
import Loading from './components/Loading/Loading';
import Intro from './components/Intro/Intro';
import History from './components/History/History';
import Landing from './components/Landing/Landing';
import Login from './components/Login/Login';
import Start from './components/Start/Start';
import Search from './components/Search/Search';
import Shop from './components/Shop/Shop';
import Category from './components/Category/Category';
import Products from './components/Products/Products';
import Product from './components/Product/Product';
import Cart from './components/Cart/Cart';
import Settings from './components/Settings/Settings';
import Addresses from './components/Addresses/Addresses';
import FAQ from './components/FAQ/FAQ';
import Friends from './components/Friends/Friends';
import Favorites from './components/Favorites/Favorites';
import Map from './components/Map/Map';
import Pay from './components/Pay/Pay';

// tslint:disable-next-line:variable-name
const UuiEnhancedApp = withRouter(({ history }) => (
  <App module={getModule(history)} />
));

// tslint:disable-next-line:variable-name
const OkosPincer = () => (
  <Root>
    <Router>
      <UuiEnhancedApp />
    </Router>
  </Root>
);

const getModule = (history: any) => {
  const id = history.location.pathname.substr(history.location.pathname.lastIndexOf('/') + 1);
  switch (history.location.pathname) {
    case '/':
      return <Redirect to={{ pathname: '/loading' }} />;
    case '/addresses':
      return <Addresses />;
    case `/category/${id}`:
      return <Category id={id} />;
    case '/cart':
      return <Cart />;
    case '/faq':
      return <FAQ />;
    case '/favorites':
      return <Favorites />;
    case '/friends':
      return <Friends />;
    case '/history':
      return <History />;
    case '/intro':
      return <Intro />;
    case '/loading':
      return <Loading />;
    case '/login':
      return <Login />;
    case '/landing':
      return <Landing />;
    case '/map':
      return <Map />;
    case `/pay/${id}`:
      return <Pay />;
    case `/products/${id}`:
      return <Products id={id} />;
    case `/product/${id}`:
      return <Product id={id} />;
    case '/search':
      return <Search />;
    case '/settings':
      return <Settings />;
    case `/shop/${id}`:
      return <Shop />;
    case '/start':
      return <Start />;
    default:
      return <NotFound />;
  }
};

serviceWorker.register();
const startApp = () =>
  ReactDOM.render(<OkosPincer />, document.getElementById('root') as HTMLElement);

// tslint:disable-next-line: no-undef
if (!Object.prototype.hasOwnProperty.call(window, 'cordova')) {
  startApp();
} else {
  document.addEventListener('deviceready', startApp, false);
}

// tslint:disable-next-line: interface-name
interface CordovaNavigator extends Navigator {
  app: {
    exitApp: () => any;
  };
}
document.addEventListener(
  'deviceready',
  () => {
    document.addEventListener(
      'backbutton',
      () => {
        if (confirm('Biztosan ki akarsz lépni?')) {
          (navigator as CordovaNavigator).app.exitApp();
        }
      },
      false,
    );
  },
  true,
);
