import * as React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
// import { dictionary } from '../Dictionaries/hun';
import style from './Header.module.less';
import menu from '../../assets/images/menu@2x.png';
import cart from '../../assets/images/cart@2x.png';
import heart from '../../assets/images/heart@2x.png';
import heart_active from '../../assets/images/heart_active@2x.png';
import { mapStateToProps, mapDispatchToProps } from './Header.connect';
import { connect } from 'react-redux';
import { IHeaderProps, IHeaderState } from './Header.interface';
import { Image } from '../Image/Image';
import BackButton from '../BackButton/BackButton';

class Header extends React.Component<IHeaderProps, IHeaderState> {
  constructor(props: IHeaderProps) {
    super(props);
    this.state = {
      animateCartIcon: false,
    };
  }

  public componentDidUpdate(prevProps: IHeaderProps) {
    if (prevProps.cartItems !== this.props.cartItems) {
      this.setState({ animateCartIcon: true });
      setTimeout(
        () => this.setState({ animateCartIcon: false }),
        1500,
      );
    }
  }

  public render() {
    const pathname = window.location.pathname.split('/')[1];
    const shop = window.location.pathname.split('/')[2];
    const { isLoggedIn, cartItems, shopSelected, selectedShop, favorites } = this.props;
    const { animateCartIcon } = this.state;
    const isInShop = isLoggedIn && shopSelected;
    const showlikeIcon = isInShop || (isLoggedIn && ['shop'].indexOf(pathname) >= 0);
    const isShopView = shop;
    const likeIconSelected = favorites.indexOf(selectedShop) >= 0
      || favorites.indexOf(isShopView) >= 0;
    return (
      <header className={style.header}>
        <nav>
          <ul>
            <li className={style.ontheleft}>
              <a
                id="mnav"
                className="icon ripple"
                onClick={() => null}
              >
                <BackButton />
              </a>
            </li>
            <li className={style.ontheleft}>
              <h2>&nbsp;</h2>
            </li>
            {isInShop &&
              <li className="cart">
                <NavLink
                  to="/cart"
                  className={`icon ripple ${animateCartIcon && 'pulseAnimation'}`}
                >
                  <Image src={cart} className="icon" />
                  <span>{cartItems}</span>
                </NavLink>
              </li>
            }
            {showlikeIcon &&
              <li className="like" onClick={this.toggleFavorite}>
                <a className="icon ripple">
                  <Image src={likeIconSelected ? heart_active : heart} className="icon" />
                </a>
              </li>
            }
            <li className="settings">
              <a
                className="icon ripple"
                onClick={this.props.action.toggleMenu}
              >
                <Image src={menu} className="icon" />
              </a>
            </li>
          </ul>
        </nav>
      </header>
    );
  }

  private toggleFavorite = () => {
    const shop = window.location.pathname.split('/')[2];
    this.props.action.toggleFavorite(this.props.userid, parseInt(shop, 10));
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
