import url_config from '../../util/url_config';

export const PRODUCTS_ACTIONS = {
  GET_PRODUCTS: '[PRODUCTS] GET_PRODUCTS',
  SUCCESS: {
    GET_PRODUCTS: '[PRODUCTS] GET_PRODUCTS_SUCCESS',
  },
  FAIL: {
    GET_PRODUCTS: '[PRODUCTS] GET_PRODUCTS_FAIL',
  },
};

export const getProducts = (store: string, cid: string) => {
  return {
    payload: {
      client: url_config.DEFAULT_CLIENT,
      request: {
        method: 'GET',
        url: url_config.GLOBAL.GET_INIT_DATA,
        params: {
          cid,
          store,
          action: 'op_get_products',
        },
      },
    },
    type: PRODUCTS_ACTIONS.GET_PRODUCTS,
  };
};
