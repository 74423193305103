import * as React from 'react';
import style from './Landing.module.less';
import refresh from '../../assets/images/refresh@2x.png';
// import { dictionary } from '../Dictionaries/hun';
import { ILandingProps } from './Landing.interface';
import { mapStateToProps, mapDispatchToProps } from './Landing.connect';
import { connect } from 'react-redux';
import { Image } from '../Image/Image';
import { NavLink } from 'react-router-dom';
import getModuleLanguage from '../Dictionaries/Dictionaries';
let dictionary: any;

const statusClasses = ['ordered', 'making', 'delivering', 'delivered'];
const statuses = (moduleDictionary: any): any => ({
  0 : moduleDictionary.status1,
  1 : moduleDictionary.status2,
  2 : moduleDictionary.status3,
  3 : moduleDictionary.status4,
});
export const formatMoney = (amount = 0, decimalCount = 2, decimal = '.', thousands = ',') => {
  try {
    let newdecimal = Math.abs(decimalCount);
    newdecimal = isNaN(newdecimal) ? 2 : newdecimal;
    const negativeSign = amount < 0 ? '-' : '';
    const i = parseInt(Math.abs(Number(amount) || 0).toFixed(newdecimal), 10).toString();
    const j = (i.length > 3) ? i.length % 3 : 0;
    return negativeSign + (j ? i.substr(0, j) + thousands : '')
      // tslint:disable-next-line: prefer-template
      + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands)
      // tslint:disable-next-line: max-line-length
      + (decimalCount ? decimal + Math.abs(amount - parseInt(i, 10)).toFixed(decimalCount).slice(2) : '');
  } catch (e) {
    return amount;
  }
};

class Landing extends React.Component<ILandingProps> {
  public interval: NodeJS.Timeout | undefined;
  constructor(props: ILandingProps) {
    super(props);
    dictionary = getModuleLanguage('landing');
  }

  public componentDidMount() {
    this.startInterval();
  }

  public componentWillUnmount() {
    this.clearInterval();
  }

  public render() {
    const { isLoading } = this.props;
    return (
      <section className={`${style.landing}`}>
        <div className={`${style.pcont} ${style.head}`}>
          <NavLink
            className={`button ${style.button} ${style.ripple}`}
            to={'category'}
            onClick={(e) => { e.preventDefault(); return false; }}
          >
            {dictionary.toProducts}
          </NavLink>
        </div>
        <div className={`${style.purp} ${style.currentorders} ${style.devider}`}>
          <a
            onClick={this.getCurrentOrders}
            className={`${style.refreshOrders} ${isLoading && 'loadingAnimation'} pulseAnimation`}
          >
            <Image src={refresh} />
          </a>
          <p>{dictionary.ordersInProgress}</p>
        </div>
        <div className={`${style.pcont} ${style.currentorders}`}>
          <div className={`${style.orderlist}`}>
            {this.renderCurrentOrders()}
          </div>
          <div className={`${style.orderlistsum}`}>
            {this.getGlobalSum()}
          </div>
        </div>
      </section>
    );
  }

  private getCurrentOrders = () => {
    this.clearInterval();
    this.startInterval();
  }

  private startInterval = () => {
    const { shop, userId } = this.props;
    if (userId && shop.blogid) {
      this.props.action.getCurrentOrders(shop.blogid, userId);
    }
    this.interval = setInterval(this.getCurrentOrders, 30000);
  }

  private clearInterval = () => {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  private renderCurrentOrders = () =>
    this.props.orders.map((item: any) =>
      item[0].payment_details.length && !item[0].payment_details[0].payment_id ?
      this.renderUnpaidOrder(item) : this.renderPaidOrder(item),
    )

  private renderUnpaidOrder = (item: any) => (
    <div className={`${style.order}`} key={`order-${item[0].ID}`}>
      <div className={`${style.indicator}`}>
      <span className={`${style.statetext}`}>
        {dictionary.ordersInProgress}<br />
        <span>
          {`${item[0].post_date} #${item[0].ID}`}
        </span>
      </span>
      <span className={`${style.amount}`}>
          {`${formatMoney(this.getSum(item[0]), 0, '.', ',')} Ft`}
      </span>
      </div>
      {item[0].table === '0' ? (
        <span className={`${style.statetext} ${style.remaining}`}>
          <NavLink to={`/pay/${item[0].payment_id}`}>
            Fizetés indítása
          </NavLink>
        </span>) : ''
      }
    </div>
  )

  private renderPaidOrder = (item: any) => (
    <div className={`${style.order}`} key={`order-${item[0].ID}`}>
      <div className={`${style.indicator}`}>
      <span className={`${style.statetext}`}>
        {dictionary.ordersInProgress}<br />
        <span>
          {`${item[0].post_date} #${item[0].ID}`}
        </span>
      </span>
      <span className={`${style.amount}`}>
          {`${formatMoney(this.getSum(item[0]), 0, '.', ',')} Ft`}
      </span>
      </div>
      {item[0].table === '0' && item[0].delivery_time ? this.getIndicator(item[0]) : ''}
    </div>
  )

  private getSum = (order: any) => {
    let sum = parseInt(order.sum, 10) || 0;
    if (order.discount && order.discount !== '') {
      const disc = JSON.parse(order.discount);
      if (disc.value) {
        sum -= parseInt(disc.value, 10);
      }
    }
    return sum;
  }

  private getIndicator = (order: any) => {
    const deliveryDate = new Date();
    const dates = order.post_date.split(':');
    deliveryDate.setHours(parseInt(dates[0], 10));
    deliveryDate.setMinutes(parseInt(dates[1], 10) + parseInt(order.delivery_time, 10));
    deliveryDate.setSeconds(0);
    const state = parseInt(order.state, 10);
    return (
      <>
      <span className={`${style.statetext} ${style.remaining}`}>
        {dictionary.estimatedRemainingTime}
        <span className={`${style.countdown}`} data-time={deliveryDate.getTime()} />
      </span>
      <div className={`${style.statuses}`}>
        { statusClasses.map((value, index) => (
          <span
            key={`indicator-${order.ID}-${index}`}
            className={`${style[value]} ${style.status} ${state >= index && style.active}`}
          >
            {statuses(dictionary)[index]}
          </span>
        ))}
      </div>
      </>
    );
  }

  private getGlobalSum = () =>
    formatMoney(this.props.orders.reduce((value, item) => value + this.getSum(item[0]), 0), 0)
}

export default connect(mapStateToProps, mapDispatchToProps)(Landing);
