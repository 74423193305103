import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { mapStateToProps, mapDispatchToProps } from './Menu.connect';
import { connect } from 'react-redux';
import style from './Menu.module.less';
import getModuleLanguage from '../Dictionaries/Dictionaries';
let dictionary: any;

import search from '../../assets/images/search@2x.png';
import mark from '../../assets/images/mark@2x.png';
import profile from '../../assets/images/profile@2x.png';
import config from '../../assets/images/config@2x.png';
import share from '../../assets/images/share@2x.png';
import lock from '../../assets/images/lock@2x.png';
import refresh from '../../assets/images/refresh@2x.png';
import logo from '../../assets/images/logo@2x.png';
import historyimg from '../../assets/images/history.png';
import { IMenuProps, IMenuState } from './Menu.interface';
import { Image } from '../Image/Image';

class Menu extends React.Component<IMenuProps, IMenuState> {
  constructor(props: IMenuProps) {
    super(props);
    dictionary = getModuleLanguage('menu');
    this.state = {
      opened: false,
    };
    setTimeout(
      () => this.setState({ opened: true }),
      10,
    );
  }

  public render() {
    const { name, id, favorites } = this.props.userData;
    const { friends, shop, history } = this.props;
    const { opened } = this.state;
    return (
      <>
        <section className={style.overlay} onClick={this.toggleMenu} />
        <section className={`${style.offcanvas} ${opened && style.open}`}>
          <div className={style.grey}>
            <header>
              <figure>
                <div>
                  <Image src={`https://graph.facebook.com/${id}/picture?width=200&height=200`} />
                </div>
                <figcaption>{name}</figcaption>
              </figure>
              {id &&
                <ul>
                  <li>
                    <NavLink
                      to="/favorites"
                      className="ripple pulseAnimation"
                      onClick={this.toggleMenu}
                    >
                      <strong>{(favorites || []).length}</strong>
                      <span>{dictionary.favorite}</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/friends" className="ripple" onClick={this.toggleMenu}>
                      <strong>{((friends && friends.data) || []).length}</strong>
                      <span>{dictionary.buddy}</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/history" className="ripple" onClick={this.toggleMenu}>
                      <strong>{(history.orders || []).length}</strong>
                      <span>{dictionary.occasion}</span>
                    </NavLink>
                  </li>
                </ul>
              }
            </header>
            <nav>
              <ul>
                <li>
                  <NavLink to="/start" className="icon ripple" onClick={this.toggleMenu}>
                    <img src={search} className="icon" />{dictionary.search}
                  </NavLink>
                </li>
                {shop &&
                  <li>
                    <NavLink
                      to={`/shop/${shop.blogid}`}
                      className="icon ripple ins"
                      onClick={this.toggleMenu}
                    >
                      <img src={mark} className="icon" />
                      <span className="storename">
                        {shop.blogname}
                      </span>
                    </NavLink>
                    <ul>
                      <li>
                        <NavLink
                          to={`/category/${shop.blogid}`}
                          className="icon"
                          onClick={this.toggleMenu}
                        >
                          {dictionary.products}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/landing" className="icon" onClick={this.toggleMenu}>
                          {dictionary.orders}
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                }
                {id &&
                  <>
                    <li>
                      <NavLink to="/settings" className="icon ripple" onClick={this.toggleMenu}>
                        <Image src={profile} className="icon" />
                        {dictionary.profile}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/history" className="ripple" onClick={this.toggleMenu}>
                        <img src={historyimg} className="icon" />
                        {dictionary.history}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/addresses" className="icon ripple" onClick={this.toggleMenu}>
                        <Image src={config} className="icon" />
                        {dictionary.addresses}
                      </NavLink>
                    </li>
                  </>
                }
                <li>
                  <NavLink to="/faq" className="icon ripple" onClick={this.toggleMenu}>
                    <img src={share} className="icon" />{dictionary.faq}
                  </NavLink>
                </li>
              </ul>
            </nav>
            <footer>
              <NavLink to="/loading" className="icon" onClick={this.toggleMenu}>
                <Image src={lock} />
              </NavLink>
              <NavLink to="/loading" className="icon" onClick={this.toggleMenu}>
                <Image src={refresh} />
              </NavLink>
              <NavLink to="/intro" className={style.logo} onClick={this.toggleMenu}>
                <Image src={logo} className="" />
              </NavLink>
            </footer>
          </div>
        </section >
      </>
    );
  }

  private toggleMenu = () => {
    this.setState({ opened: false });
    setTimeout(
      () => this.props.action.toggleMenu(),
      300,
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
