import { toggleMenu } from '../Menu/Menu.action';
import { ICartItem } from '../Cart/Cart.interface';
import { toggleFavorite } from '../Login/Login.action';

export const mapStateToProps = ({ Login, Cart, App }: any) => ({
  isLoggedIn: Object.keys(Login.userData).length > 0,
  cartItems: Cart.cart.cartItems
    .reduce((sum: number, current: ICartItem) => sum + current.quantity, 0),
  shopSelected: App.selectedShop !== '0',
  selectedShop: App.selectedShop,
  favorites: (Login.userData.favorites || []).map((favorite: any) => favorite.blog_id),
  userid: Login.userData.id || '',
});

export const mapDispatchToProps = (dispatch: any) => ({
  action: {
    toggleMenu: () => dispatch(toggleMenu()),
    toggleFavorite: (user: string, blogId: number) => dispatch(toggleFavorite(user, blogId)),
  },
});
