declare const google: any;
import * as React from 'react';
import ReactDomServer from 'react-dom/server';
import { mapStateToProps, mapDispatchToProps } from './Map.connect';
import { connect } from 'react-redux';
import axios from 'axios';
import { IMapProps, IMapState } from './Map.interface';
import style from './Map.module.less';
import { IShop } from '../Search/Search.interface';
import op_map from '../../assets/images/op_map.png';
import { Redirect } from 'react-router-dom';
import Toast from '../Toast/Toast';
import { get } from 'lodash';

class Map extends React.Component<IMapProps, IMapState> {
  private map: any = null;
  private apiURL: string = 'https://maps.googleapis.com/maps/api/geocode/json';
  private apiKey: string = 'AIzaSyAkOZaZxezRXLhx6RVksj84grYS0vNnJAE';
  constructor(props: IMapProps) {
    super(props);
    this.state = {
      redirect: '',
    };
  }

  public componentDidMount() {
    if (typeof google !== 'undefined') {
      this.props.action.getShops()
      .then(() => {
        this.init();
      });
    }
  }

  public render() {
    const { redirect } = this.state;
    if (redirect) {
      return (<Redirect to={{ pathname: redirect }} />);
    }
    return (
      <section className={style.map}>
        <div id="map_canvas" className={style.map_canvas} />
      </section>
    );
  }

  private init = () => {
    if (this.map) {
      google.maps.event.trigger(this.map, 'resize');
      return;
    }
    const mapProp = {
      center: new google.maps.LatLng(46.2494515, 20.1478035),
      zoom: 13,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    };
    this.map = new google.maps.Map(document.getElementById('map_canvas'), mapProp);
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        const initialLocation = new google.maps.LatLng(latitude, longitude);
        this.map.setCenter(initialLocation);
      },
      () => {
        Toast('Nem sikerült beállítani a pozíciódat!');
      },
    );
    this.drawMap();
  }

  private gMap = async (address: string, content: string, index: number) => {
    let data: any;
    try {
      data =
        await axios.get(`${this.apiURL}?sensor=false&key=${this.apiKey}&address=${address}`);
    } catch (error) {
      return false;
    }
    if (!data.data.results || typeof data.data.results[0] === 'undefined') {
      return false;
    }
    const infowindow = new google.maps.InfoWindow();
    const bounds = new google.maps.LatLngBounds();
    const p = data.data.results[0].geometry.location;
    const latlng = new google.maps.LatLng(p.lat, p.lng);
    const marker = new google.maps.Marker({
      map: this.map,
      position: latlng,
      icon: op_map,
    });
    marker.setMap(null);
    setTimeout(
      () => {
        marker.setMap(this.map);
        bounds.extend(marker.position);
        marker.setAnimation(google.maps.Animation.DROP);
        google.maps.event.trigger(this.map, 'resize');
      },
      index * 200,
    );
    google.maps.event.addListener(marker, 'click', () => {
      infowindow.setContent(content);
      infowindow.open(this.map, marker);
    });
    google.maps.event.addListener(infowindow, 'domready', () => {
      const element = document.querySelector('[class^="Map_shop"]');
      if (element) {
        element.addEventListener('click', (e) => {
          e.preventDefault();
          const blogid = get(e, 'target.dataset.blogid', '');
          if (blogid) {
            this.props.action.selectShop(blogid);
            this.setState({ redirect: `/shop/${blogid}` });
          }
        });
      }
    });
  }

  private drawMap = () => {
    this.props.shops.forEach((item: IShop, index: number) => {
      const content = ReactDomServer.renderToString(
        <span className={style.flyout_content}>
          <b>{item.blogname}</b><br />
          {item.address}<br />
          <a className={style.shop} data-blogid={item.blogid}>
            Beülök
          </a>
        </span>,
      );
      this.gMap(item.address, content, index);
    });
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Map);
