import { clone } from 'lodash';
import { PRODUCTS_ACTIONS } from './Products.action';

const defaultAppState: any = {
  isLoading: true,
  products: [],
  cachedProducts: [],
};

// tslint:disable-next-line: variable-name
export const Products = (
  state = clone(defaultAppState),
  action: any,
) => {
  switch (action.type) {
    case PRODUCTS_ACTIONS.GET_PRODUCTS:
      return {
        ...state,
        isLoading: true,
      };
    case PRODUCTS_ACTIONS.SUCCESS.GET_PRODUCTS:
      const cachedProducts = [
        ...state.cachedProducts,
        ...action.payload.data.data,
      ];
      return {
        ...state,
        cachedProducts,
        isLoading: false,
        products: action.payload.data.data,
      };
    case PRODUCTS_ACTIONS.FAIL.GET_PRODUCTS:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
