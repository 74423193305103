import url_config from '../../util/url_config';

export const CATEGORY_ACTIONS = {
  CHECK_IN: '[CATEGORY] CHECK_IN',
  GET_CATEGORIES: '[CATEGORY] GET_CATEGORIES',
  GET_TOPPINGS: '[CATEGORY] GET_TOPPINGS',
  GET_MINUSES: '[CATEGORY] GET_MINUSES',
  GET_SLIDES: '[CATEGORY] GET_SLIDES',
  GET_SHOP_DATA: '[CATEGORY] GET_SHOP_DATA',
  SUCCESS: {
    CHECK_IN: '[CATEGORY] CHECK_IN_SUCCESS',
    GET_CATEGORIES: '[CATEGORY] GET_CATEGORIES_SUCCESS',
    GET_TOPPINGS: '[CATEGORY] GET_TOPPINGS_SUCCESS',
    GET_MINUSES: '[CATEGORY] GET_MINUSES_SUCCESS',
    GET_SLIDES: '[CATEGORY] GET_SLIDES_SUCCESS',
    GET_SHOP_DATA: '[CATEGORY] GET_SHOP_DATA_SUCCESS',
  },
  FAIL: {
    CHECK_IN: '[CATEGORY] CHECK_IN_FAIL',
    GET_CATEGORIES: '[CATEGORY] GET_CATEGORIES_FAIL',
    GET_TOPPINGS: '[CATEGORY] GET_TOPPINGS_FAIL',
    GET_MINUSES: '[CATEGORY] GET_MINUSES_FAIL',
    GET_SLIDES: '[CATEGORY] GET_SLIDES_FAIL',
    GET_SHOP_DATA: '[CATEGORY] GET_SHOP_DATA_FAIL',
  },
};

export const checkIn = (id: string) => {
  return {
    payload: {
      client: url_config.DEFAULT_CLIENT,
      request: {
        method: 'GET',
        url: url_config.GLOBAL.GET_INIT_DATA,
        params: {
          action: 'op_check_in',
          blog: id,
          table: 0,
        },
      },
    },
    type: CATEGORY_ACTIONS.CHECK_IN,
  };
};

export const getCategories = () => {
  return {
    payload: {
      client: url_config.DEFAULT_CLIENT,
      request: {
        method: 'GET',
        url: url_config.GLOBAL.GET_INIT_DATA,
        params: {
          action: 'op_get_categories',
        },
      },
    },
    type: CATEGORY_ACTIONS.GET_CATEGORIES,
  };
};

export const getToppings = () => {
  return {
    payload: {
      client: url_config.DEFAULT_CLIENT,
      request: {
        method: 'GET',
        url: url_config.GLOBAL.GET_INIT_DATA,
        params: {
          action: 'op_get_toppings',
        },
      },
    },
    type: CATEGORY_ACTIONS.GET_TOPPINGS,
  };
};

export const getMinuses = () => {
  return {
    payload: {
      client: url_config.DEFAULT_CLIENT,
      request: {
        method: 'GET',
        url: url_config.GLOBAL.GET_INIT_DATA,
        params: {
          action: 'op_get_minuses',
        },
      },
    },
    type: CATEGORY_ACTIONS.GET_MINUSES,
  };
};

export const getSlides = () => {
  return {
    payload: {
      client: url_config.DEFAULT_CLIENT,
      request: {
        method: 'GET',
        url: url_config.GLOBAL.GET_INIT_DATA,
        params: {
          action: 'op_get_slides',
        },
      },
    },
    type: CATEGORY_ACTIONS.GET_SLIDES,
  };
};

export const getShopData = (blogid: string) => {
  return {
    payload: {
      client: url_config.DEFAULT_CLIENT,
      request: {
        method: 'GET',
        url: url_config.GLOBAL.GET_INIT_DATA,
        params: {
          blogid,
          action: 'op_get_shop_data',
        },
      },
    },
    type: CATEGORY_ACTIONS.GET_SHOP_DATA,
  };
};
