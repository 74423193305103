import * as React from 'react';
import style from './Cart.module.less';
import refresh from '../../assets/images/refresh@2x.png';
import { mapStateToProps, mapDispatchToProps } from './Cart.connect';
import { connect } from 'react-redux';
import { Image } from '../Image/Image';
import {
  ICartProps,
  ICartItem,
  ICartState,
  IScrollItem,
  ICartFields,
  IDelivery,
} from './Cart.interface';
import icon_rendelestipus from '../../assets/images/icon_rendelestipus@2x.png';
import icon_idopont from '../../assets/images/icon_idopont@2x.png';
import truck from '../../assets/images/truck@2x.png';
import discountIcon from '../../assets/images/discount@2x.png';
import icon_szamla from '../../assets/images/icon_szamla@2x.png';
import icon_asztal from '../../assets/images/icon_asztal@2x.png';
import payment from '../../assets/images/payment@2x.png';
import coupon from '../../assets/images/coupon@2x.png';
import notes from '../../assets/images/notes@2x.png';
import select_elvitel from '../../assets/images/select_elvitel@2x.png';
import shipping from '../../assets/images/shipping@2x.png';
import { IProduct } from '../Products/Products.interface';
import { isEqual, get } from 'lodash';
import { ITopping, IMinus } from '../Category/Category.interface';
import { IAddress } from '../Menu/Menu.interface';
import { NavLink, Redirect } from 'react-router-dom';
import getModuleLanguage from '../Dictionaries/Dictionaries';
import Toast from '../Toast/Toast';
import Scroller from '../Scroller/Scroller';
import { formatMoney } from '../Landing/Landing';
let dictionary: any;

const orderTypes = (moduleDictionary: any): any => ({
  0: moduleDictionary.delivery,
  E: moduleDictionary.takeway,
});
const scrollerTitles = (moduleDictionary: any): any => ({
  orderType: moduleDictionary.orderType,
  deliveryAddress: moduleDictionary.deliveryAddress,
  billingAddress: moduleDictionary.billingAddress,
  paymentMethod: moduleDictionary.paymentMethod,
  message: moduleDictionary.message,
});
export const getFormattedAddress = (address: IAddress) =>
  // tslint:disable-next-line: max-line-length prefer-template
  `${address.company && address.company + ' '}${address.zip} ${address.city}, ${address.street} ${address.number}`;

class Cart extends React.Component<ICartProps, ICartState> {
  constructor(props: ICartProps) {
    super(props);
    dictionary = getModuleLanguage('cart');
    this.state = {
      sum: 0,
      deliveryPrice: 0,
      paymentMethod: 0,
      deliveryAddress: (props.addresses && props.addresses[0]) || {},
      billingAddress: {},
      orderType: '0',
      table: '1',
      message: '',
      deliveryTime: '',
      showScroller: '',
      cartItems: [],
      elements: [],
      isCloseDisabled: true,
      cupon: '',
      redirect: '',
    };
    if (!props.cart.deliveryAddress.id) {
      props.action.updateCart('deliveryAddress', (props.addresses && props.addresses[0]) || {});
    }
  }

  public componentDidMount() {
    if (this.props.addresses && !this.props.addresses.length) {
      this.setState({ redirect: 'addresses' });
    }
    this.calculateSummary();
  }

  public componentDidUpdate(prevProps: ICartProps) {
    if (!isEqual(prevProps, this.props)) {
      this.calculateSummary();
    }
  }

  public render() {
    const {
      isLoading,
      shop,
      cart,
    } = this.props;
    if (!shop) {
      return null;
    }
    const { blog_minimum_order_limit, blog_payment, blog_payment_delivery } = shop;
    const {
      billingAddress,
      deliveryAddress,
      cartItems,
      paymentMethod,
      orderType,
      deliveryTime,
      message,
      table,
      cuponData,
    } = cart;
    const {
      sum,
      showScroller,
      isCloseDisabled,
      redirect,
    } = this.state;
    if (redirect) {
      this.setState({ redirect: '' });
      return (<Redirect to={{ pathname: redirect }} />);
    }
    const discount = cuponData.discount ? cuponData.discount : null;
    const isOrderDelivery = orderType === '0';
    const isOrderTakeaway = orderType === 'E';
    const isOrderLocal = !isOrderDelivery && !isOrderTakeaway;
    const methods = isOrderDelivery ? blog_payment_delivery : blog_payment;
    const deliveryPrice = this.calculateDelivery(false);
    const discountPrice = parseInt(cuponData.discount, 10);
    const productsSum = sum - deliveryPrice - discountPrice;
    const isOrderUnderMinimum =
      parseInt(blog_minimum_order_limit, 10) > productsSum && isOrderDelivery;
    const isTextarea = ['message', 'cupon'].indexOf(showScroller) >= 0;
    const isSubmitDisabled = (
      (isOrderDelivery && isCloseDisabled)
      || (isOrderTakeaway && !deliveryTime)
    );
    const scrollerProps = {
      isTextarea,
      hideScroller: 'cupon' !== showScroller ? this.hideScroller : this.sendCode,
      renderScrollerElements: isTextarea ?
        this.renderScrollerContent(showScroller) : this.renderScrollerElements(showScroller),
      title: scrollerTitles(dictionary)[showScroller],
      selectLabel: dictionary.select,
    };
    return (
      <section className={style.order}>
        {isLoading &&
          <div className="loading loadingAnimation">
            <Image src={refresh} />
          </div>
        }
        <ul className={style.cartItems}>
          {cartItems.length === 0 && this.renderEmptyCartItems()}
          {cartItems.length > 0 && this.renderCartItems()}
          {cartItems.length > 0 && isOrderDelivery && this.renderDeliveryPrice()}
          {cartItems.length > 0 && discount && this.renderDiscount()}
        </ul>
        <div className={style.summary}>
          <div className={style.desc}><b>{dictionary.summary}</b></div>
          <div className={style.price}><h5>{formatMoney(this.state.sum, 0)} Ft</h5></div>
        </div>
        <div className={style.bottomgrey}>
          {isOrderUnderMinimum &&
            <h5 className="mimimal_order_info">
              {`${dictionary.minimalAmount} ${blog_minimum_order_limit} Ft`}
            </h5>}
          <div className="commentcontainer hidden">
            <div>
              <b>{dictionary.comment}</b>
              <input className="comment" type="text" required={true} />
              <label>{dictionary.addComment}</label>
              <button className="forcebg submit">{dictionary.ready}</button>
              <button className="transp cancel">{dictionary.cancel}</button>
            </div>
          </div>
          <div className="cupon-input hidden">
            <div>
              <b>{dictionary.cupon}</b>
              <input id="cupon-code" type="text" required={true} />
              <label>{dictionary.enterCuponCode}</label>
              <a href="#" className="button ripple progress-button send-cupon">
                {dictionary.send}
              </a>
              <button className="transp cancel">
                {dictionary.cancel}
              </button>
            </div>
          </div>
          <div className={style.button_block}>
            <div className={style.whitebg} onClick={this.showScroller('orderType')}>
              <Image src={icon_rendelestipus} className={style.icon} />
              <span className={style.blockname}>{dictionary.orderType}</span>
              <span className={style.blockvalue}>
                {orderTypes(dictionary)[orderType] || dictionary.local}
              </span>
            </div>
            {isOrderTakeaway &&
              <div className={style.whitebg} onClick={this.showScroller('deliveryTime')}>
                <input type="hidden" id="order_delivery_time" />
                <Image src={icon_idopont} className={style.icon} />
                <span className={style.blockname}>{dictionary.takewayTime}</span>
                <span className={style.blockvalue}>
                  {deliveryTime || dictionary.notSelected}
                </span>
              </div>
            }
            {isOrderDelivery &&
              <div className={style.whitebg} onClick={this.showScroller('deliveryAddress')}>
                <input type="hidden" id="order_delivery_address" />
                <Image src={truck} className={style.icon} />
                <span className={style.blockname}>{dictionary.deliveryAddressLabel}</span>
                <span className={style.blockvalue}>
                  {(deliveryAddress && deliveryAddress.id && getFormattedAddress(deliveryAddress))
                    || dictionary.notSelected}
                </span>
              </div>
            }
            <div className={style.whitebg} onClick={this.showScroller('billingAddress')}>
              <input type="hidden" id="order_billing_address" />
              <Image src={icon_szamla} className={style.icon} />
              <span className={style.blockname}>{dictionary.billingAddressLabel}</span>
              <span className={style.blockvalue}>
                {(billingAddress && billingAddress.zip && getFormattedAddress(billingAddress))
                  || dictionary.notSelected}
              </span>
            </div>
            {isOrderLocal &&
              <div
                className={style.whitebg}
                onClick={this.showScroller('table')}
              >
                <input type="hidden" id="order_local_table" />
                <Image src={icon_asztal} className={style.icon} />
                <span className={style.blockname}>{dictionary.tableNumber}</span>
                <span className={style.blockvalue}>
                  {`${table}. ${dictionary.table}` || dictionary.notSelected}
                </span>
              </div>
            }
            <div
              className={`${style.whitebg} ${style.half}`}
              onClick={this.showScroller('paymentMethod')}
            >
              <input type="hidden" id="order_payment" />
              <Image src={payment} className={style.icon} />
              <span className={style.blockname}>{dictionary.paymentMethod}</span>
              <span className={style.blockvalue}>
                {methods[paymentMethod] || dictionary.notSelected}
              </span>
            </div>
            <div
              className={`${style.whitebg} ${style.half} ${style.leftpadding}`}
              onClick={this.showScroller('cupon')}
            >
              <input type="hidden" id="order_coupon" />
              <Image src={coupon} className={style.icon} />
              <span className={style.blockname}>{dictionary.cupon}</span>
              <span className={style.blockvalue}>
                {discount ? `${formatMoney(parseInt(discount, 10), 0)} Ft` : ' '}
              </span>
            </div>
            <div
              className={style.whitebg}
              onClick={this.showScroller('message')}
            >
              <input type="hidden" id="order_note" />
              <Image src={notes} className={style.icon} />
              <span className={style.blockname}>{dictionary.message}</span>
              <span className={style.blockvalue}>
                {message || ''}
              </span>
            </div>
          </div>
        </div>
        <div className={style.cart_button_holder}>
          <NavLink
            className="button ripple progress-button close-order"
            to={'landing'}
            onClick={this.closeOrder}
            data-disabled={isSubmitDisabled}
          >
            {dictionary.closeOrder}
          </NavLink>
          <a href="#" className="button transp ripple">
            {dictionary.emptyOrder}
          </a>
        </div>
        {showScroller && <Scroller {...scrollerProps} /> }
      </section>
    );
  }

  private hideScroller = () => {
    const { showScroller } = this.state;
    this.props.action.updateCart(showScroller, this.state[showScroller]);
    this.calculateSummary();
    if (showScroller === 'orderType' && this.state.orderType === 'E') {
      this.showScroller('deliveryTime')();
    } else {
      this.setState({ showScroller: '' });
    }
  }

  private calculateSummary = () => {
    const deliveryPrice = this.calculateDelivery();
    const { cuponData } = this.props.cart;
    const discount = parseInt(cuponData.discount, 10);
    let sum = this.sumCartItems();
    sum += deliveryPrice;
    sum -= discount;
    if (sum < 0) {
      sum = 0;
    }
    this.setState({ sum, deliveryPrice });
  }

  private sumCartItems = () =>
    this.props.cart.cartItems
    .reduce(
      (summary: number, current: ICartItem) => {
        const price = (this.props.cart.orderType === '0' ? current.package : 0) + current.price;
        return summary + (price * current.quantity);
      },
      0,
    )

  private renderEmptyCartItems = () =>
    (
      <li
        className={style.removeCart}
        key={'cartItem-0'}
      >
        <div className={style.desc}>
          <b>{dictionary.cartIsEmpty}</b>
        </div>
      </li>
    )

  private renderDeliveryPrice = () => {
    const { deliveryPrice } = this.state;
    return deliveryPrice ? (
      <li
        className={'delivery_price'}
      >
        <Image className={`${style.circle} circle`} src={truck} />
        <div className={style.desc}>
          <b>{dictionary.deliveryPrice}</b>
        </div>
        <div className={style.price}>{deliveryPrice} Ft</div>
      </li>
    ) : null;
  }

  private renderDiscount = () => {
    const { cuponData } = this.props.cart;
    const discount = cuponData.discount;
    return discount ? (
      <li
        className={'discount'}
      >
        <Image className={`${style.circle} circle`} src={discountIcon} />
        <div className={style.desc}>
          <b>{dictionary.discount}</b>
        </div>
        <div className={style.price}>{formatMoney(parseInt(discount, 10), 0)} Ft</div>
      </li>
    ) : null;
  }

  private renderCartItems = () =>
    this.props.cart.cartItems.map((item: ICartItem, index: number) => {
      const product = this.props.products.filter((pitem: IProduct) => pitem.pid === item.pid)[0];
      const findToppingName = (titem: any) =>
      this.props.toppings.filter((topping: ITopping) =>
          topping.cid === parseInt(titem[0], 10))[0].cname;
      const findMinusName = (mitem: any) =>
        this.props.minuses.filter((minus: IMinus) =>
          minus.id === parseInt(mitem[0], 10))[0].name;
      const toppings = item.toppings.map((titem: any) => findToppingName(titem));
      const minuses = item.minuses.map((mitem: any) => findMinusName(mitem));
      return (
        <li
        className={style.removeCart}
          key={`cartItem-${index}`}
          onClick={this.removeFromCart(index)}
          >
          <Image className={`${style.circle} circle`} src={product.pimage} />
          <div className={style.desc}>
            <b>{product.pname}</b>
            <br />
            {item.quantity} x {item.price} Ft
            {toppings.length ? ` + ${toppings.join(' + ')}` : ''}
            {minuses.length ? ` - ${minuses.join(' - ')}` : ''}
          </div>
          <div className={style.price}>{(item.price * item.quantity)} Ft</div>
        </li>
      );
    })

  private removeFromCart = (productIndex: number) =>
    () => {
      if (confirm(dictionary.removeFromCartConfirm)) {
        this.props.action.removeFromCart(productIndex);
      }
    }

  private showScroller = (showScroller: string) =>
    () => {
      let elements: IScrollItem[] = [];
      const { shop, cart } = this.props;
      const { blog_payment, blog_payment_delivery, tables } = shop;
      const { orderType } = cart;
      const isOrderDelivery = orderType === '0';
      const methods = isOrderDelivery ? blog_payment_delivery : blog_payment;
      switch (showScroller) {
        case 'orderType':
          elements = [
            {
              image: truck,
              value: '0',
              title: dictionary.delivery,
              description: '',
            },
            {
              image: select_elvitel,
              value: 'E',
              title: dictionary.takeway,
              description: '',
            },
            {
              image: shipping,
              value: '1',
              title: dictionary.local,
              description: '',
            },
          ];
          break;
        case 'deliveryAddress':
        case 'billingAddress':
          elements = this.props.addresses.map((item: IAddress) => ({
            image: truck,
            value: item,
            title: item.title,
            description: getFormattedAddress(item),
          }));
          break;
        case 'paymentMethod':
          elements = methods.map((item: string, index: number) => ({
            image: payment,
            value: index,
            title: item,
            description: '',
          }));
          break;
        case 'deliveryTime':
          const { blog_open, delivery_time = '30' } = this.props.shop;
          const timeUnit = 10;
          const calculateTime = (hour: string, minute: string) =>
            parseInt(hour, 10) * 60
            + Math.ceil(parseInt(minute, 10) / timeUnit) * timeUnit
            + parseInt(delivery_time, 10);
          const orderTime = new Date();
          let n = orderTime.getDay() - 1;
          if (n === -1) {
            n = 6;
          }
          const openTime = blog_open[n * 2].split(':');
          const closeTime = blog_open[(n * 2) + 1].split(':');
          if (parseInt(closeTime[0], 10) <= parseInt(openTime[0], 10)) {
            closeTime[0] = `${parseInt(closeTime[0], 10) + 24}`;
          }
          const initValue = Math.max(
            calculateTime(openTime[0], openTime[1]),
            calculateTime(`${orderTime.getHours()}`, `${orderTime.getMinutes()}`),
          );
          for (let i = initValue; i <= parseInt(closeTime[0], 10) * 60; i += timeUnit) {
            const hour = (`0${Math.floor(i / 60) % 24}`)
            .slice(-2);
            const minutes = `0${i % 60}`.slice(-2);
            const value = `${hour}:${minutes}`;
            elements.push({
              value,
              image: '',
              title: value,
              description: '',
            });
          }
          if (!elements.length) {
            elements.push({
              value: '',
              image: '',
              title: dictionary.timeNotAvailable,
              description: '',
            });
          }
          break;
        case 'table':
          // tslint:disable-next-line: prefer-array-literal
          elements = new Array(parseInt(tables, 10))
          .fill(0)
          // tslint:disable-next-line: variable-name
          .map((_item: number, index: number) => ({
            image: '',
            value: `${index + 1}`,
            title: `${index + 1}. ${dictionary.table}`,
            description: '',
          }));
          break;
      }
      this.setState({ showScroller, elements });
    }

  private renderScrollerElements = (type: string) =>
    () =>
      this.state.elements.map((item: IScrollItem, index: number) => (
        <li
          className={`${item.value === this.state[type] && style.active} ${style.oneliner}`}
          key={`scroll-${index}`}
          onClick={() => this.setState({ [type]: item.value })}
        >
          <Image src={item.image} className={style.scroller_icon} />
          <span className={style.item}>{item.title}</span>
          <span>{item.description}</span>
        </li>
      ))

  private closeOrder = async (event: any) => {
    event.preventDefault();
    let { message } = this.state;
    const {
      paymentMethod: method,
      deliveryAddress: address,
      billingAddress,
      orderType,
      deliveryPrice,
      cartItems,
      cupon,
      table: localTable,
    } = this.props.cart;
    const table = orderType === '1' ? localTable : orderType;
    const isCartEmpty = !cartItems.length;
    if (isCartEmpty) {
      Toast(dictionary.cartIsEmpty);
      return false;
    }
    /* if (!this.validateCart()) {
      Toast(dictionary.cartIsEmpty);
      return false;
    }*/
    // tslint:disable-next-line: max-line-length prefer-template
    message = `${message}${billingAddress.zip ? ` ${dictionary.billingAddressLabel}: `
      + getFormattedAddress(billingAddress) : ''}`;
    const data: ICartFields = {
      message,
      method,
      table,
      cupon,
      address: address.id,
      userId: this.props.userId,
      blog_id: parseInt(this.props.shop.blogid, 10),
      delivery: `${deliveryPrice}`,
      delivery_time: '',
      cartItems: cartItems.map(this.mapCartItems),
    };
    await this.props.action.closeOrder(data)
    .then((resp: any) => {
      const response = get(resp, 'payload.data');
      Toast(response.data);
      // const redirect = 'landing';
      const redirect = response.payment_id ? `pay/${response.payment_id}` : 'landing';
      /*if (response.payment_id) {
        if (Object.prototype.hasOwnProperty.call(window, 'cordova')) {
          window.open = cordova.InAppBrowser.open;
        }
        window.open(`http://okospincerpay.tk/${response.payment_id}`, '_blank', 'location=yes');
      }*/
      if (response.success) {
        this.setState({
          redirect,
          sum: 0,
          paymentMethod: 0,
          deliveryAddress: (this.props.addresses && this.props.addresses[0]) || {},
          billingAddress: {},
          orderType: '0',
          table: '1',
          message: '',
          deliveryTime: '',
          showScroller: '',
          elements: [],
        });
      }
    });
  }

  /* private validateCart = () => {
    let valid = true;
    return valid;
  }*/

  private mapCartItems = (item: ICartItem) => {
    const product = this.props.products.filter((pitem: IProduct) => pitem.pid === item.pid)[0];

    const price = (this.state.orderType === '0' ? item.package : 0) + item.price;
    return [
      item.pid,
      product.pname,
      item.price,
      item.quantity,
      product.extent,
      product.pimage,
      item.toppings,
      item.minuses,
      item.package,
      product.prod_type,
      (price * item.quantity),
    ];
  }

  private calculateDelivery = (setState: boolean = true) => {
    if (!this.props.shop) {
      return 0;
    }
    const { orderType } = this.state;
    let deliveryPrice = 0;
    let isCloseDisabled = true;
    const productsSum = this.sumCartItems();
    const isAmountExeedsFreeDeliveryLimit =
      parseInt(this.props.shop.blog_free_delivery_limit, 10) <= productsSum;
    if (orderType === '0' && !isAmountExeedsFreeDeliveryLimit) {
      let { deliveries = {} } = this.props.shop;
      deliveries = Object.keys(deliveries).map(key => deliveries[key]);
      const { zip } = this.state.deliveryAddress;
      deliveries.forEach((delivery: IDelivery) => {
        const { zip: dzip, price: dprice } = delivery;
        if (dzip.indexOf('...') >= 0) {
          const zips = dzip.split('...');
          const currentZip = parseInt(zip, 10);
          if (parseInt(zips[0], 10) <= currentZip && parseInt(zips[1], 10) >= currentZip) {
            deliveryPrice = parseInt(dprice, 10);
            isCloseDisabled = false;
          }
        } else if (dzip.indexOf(zip) >= 0) {
          deliveryPrice = parseInt(dprice, 10);
          isCloseDisabled = false;
        }
      });
    }
    if (setState) {
      this.setState({
        isCloseDisabled,
      });
      this.props.action.updateCart('deliveryPrice', deliveryPrice);
    }
    return deliveryPrice;
  }

  private renderScrollerContent = (type: string) =>
    () => (
      <>
        <input
          type="text"
          required={true}
          defaultValue={this.state[type]}
          onChange={(e: any) => this.setState({ [type]: e.target.value })}
        />
        <label>{dictionary[type === 'cupon' ? 'enterCuponCode' : 'enterMessage']}</label>
      </>
    )

  private sendCode = () => {
    if (!this.state.cupon) {
      this.hideScroller();
      return false;
    }
    this.props.action.validateCupon(this.state.cupon, this.props.shop.blogid)
      .then((response) => {
        Toast(response.payload.data.message);
        if (response.payload.data.success) {
          this.setState({ cupon: response.payload.data.code });
          this.hideScroller();
        }
      });
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
