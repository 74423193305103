import { removeFromCart, closeOrder, updateCart, validateCupon } from './Cart.action';
import { IShop } from '../Search/Search.interface';
import { ICartFields } from './Cart.interface';

export const mapStateToProps = ({ Cart, Category, Products, Search, App, Login }: any) => ({
  isLoading: Cart.isLoading,
  cart: Cart.cart,
  toppings: Category.toppings,
  minuses: Category.minuses,
  products: Products.cachedProducts,
  userId: Login.userData.id,
  addresses: Login.userData.addresses,
  shop: Search.shops.filter((shop: IShop) =>
    shop.blogid === App.selectedShop)[0],
});

export const mapDispatchToProps = (dispatch: any) => ({
  action: {
    removeFromCart: (index: number) => dispatch(removeFromCart(index)),
    closeOrder: (data: ICartFields) => dispatch(closeOrder(data)),
    validateCupon: (code: string, blogid: string) => dispatch(validateCupon(code, blogid)),
    updateCart: (field: string, value: any) => dispatch(updateCart(field, value)),
  },
});
