import * as React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import ReactSwipe from 'react-swipe';
import refresh from '../../assets/images/refresh@2x.png';
import { mapDispatchToProps, mapStateToProps } from './Category.connect';
import { ICategory, ICategoryProps, ISlide, ICategoryState } from './Category.interface';
import style from './Category.module.less';
import { get } from 'lodash';
import { Image } from '../Image/Image';

class Category extends React.Component<ICategoryProps, ICategoryState> {
  constructor(props: ICategoryProps) {
    super(props);
    this.state = {
      isDataLoading: true,
    };
    this.getData();
  }

  public render() {
    const { isLoading, categories, slides, shop } = this.props;
    const { isDataLoading } = this.state;
    const defaultImage: ISlide = {
      slide_textalign: 'left',
      slide_showtitle: '',
      slide_showexcerpt: '',
      kep_image: get(shop, 'image', ''),
      slide_excerpt: '',
      slide_name: '',
      slide_readmoreurl: '',
      slide_status: '',
      slide_showreadmore: '',
      post_id: 0,
    };
    return (
      <section className={`${style.category}`}>
        {(isLoading || isDataLoading) &&
          <div className="loading loadingAnimation">
            <img src={refresh} />
          </div>
        }
        <div className="slides" id="slides2">
          <ReactSwipe
            swipeOptions={{ continuous: false }}
            childCount={slides.length}
          >
            {slides.length > 0 && slides.map(this.mapSlides)}
            {!slides.length && [defaultImage].map(this.mapSlides)}
          </ReactSwipe>
        </div>
        <div className="stickylist">
          <ul>
            {categories.map(this.mapCategories)}
          </ul>
        </div>
      </section>
    );
  }

  private getData = () => {
    this.props.action.getShopData(this.props.shop.blogid)
    .then(() => {
      this.setState({ isDataLoading: false });
    });
  }

  private mapCategories = (category: ICategory) => (
    <li key={`category-${category.cid}`}>
      <NavLink to={`/products/${category.cid}`}>
        <Image className="circle" src={category.cthumb} />
        <div className="desc">
          <b>{category.cname}</b>
          <div dangerouslySetInnerHTML={{ __html: category.cdesc }} />
        </div>
      </NavLink>
    </li>
  )

  private mapSlides = (item: ISlide, index: number) =>
    <div
      key={`slide-${index}`}
      className={`${style.slide} ${item.slide_textalign}`}
      style={{ backgroundImage: `url(${item.kep_image})` }}
    >
      <a href="#product">
        <h3>{item.slide_showtitle === 'on' && item.slide_name}</h3>
        <p>{item.slide_showexcerpt === 'on' && item.slide_excerpt}</p>
      </a>
    </div>
}

export default connect(mapStateToProps, mapDispatchToProps)(Category);
