import url_config from '../../util/url_config';
import { CART_ACTIONS } from '../Cart/Cart.action';
import { ICartItem } from '../Cart/Cart.interface';

export const PRODUCT_ACTIONS = {
  GET_PRODUCT: '[PRODUCT] GET_PRODUCT',
  SUCCESS: {
    GET_PRODUCT: '[PRODUCT] GET_PRODUCT_SUCCESS',
  },
  FAIL: {
    GET_PRODUCT: '[PRODUCT] GET_PRODUCT_FAIL',
  },
};

export const getProduct = (store: string, pid: string) => {
  return {
    payload: {
      client: url_config.DEFAULT_CLIENT,
      request: {
        method: 'GET',
        url: url_config.GLOBAL.GET_INIT_DATA,
        params: {
          pid,
          store,
          action: 'admin_get_product',
        },
      },
    },
    type: PRODUCT_ACTIONS.GET_PRODUCT,
  };
};

export const addToCart = (product: ICartItem) => ({
  product,
  type: CART_ACTIONS.ADD_TO_CART,
});
