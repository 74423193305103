import * as React from 'react';
import { NavLink } from 'react-router-dom';
import style from './Products.module.less';
import refresh from '../../assets/images/refresh@2x.png';
import { mapStateToProps, mapDispatchToProps } from './Products.connect';
import { connect } from 'react-redux';
import { IProductsProps, IProduct } from './Products.interface';
import { Image } from '../Image/Image';

class Products extends React.Component<IProductsProps> {
  constructor(props: IProductsProps) {
    super(props);
    if (props.shop && props.id) {
      props.action.getProducts(props.shop.blogid, props.id);
    }
  }

  public render() {
    const { isLoading, products } = this.props;
    return (
      <section className={style.productlist}>
        {isLoading &&
          <div className="loading loadingAnimation">
            <img src={refresh} />
          </div>
        }
        <div className={style.boxes}>
          <ul>
            {products.map(this.mapProduct)}
          </ul>
        </div>
      </section>
    );
  }

  private mapProduct = (item: IProduct) =>
    <li className="tcenter" key={`product-${item.pid}`}>
      <NavLink to={`/product/${item.pid}`}>
        <div className={style.imgcont} >
          <Image src={item.pimage} />
        </div>
        <h4>{item.pname}</h4>
        <p className={item.pdiscountprice && style.discount}>
          <span>{item.pprice} Ft</span>&nbsp;
          {item.pdiscountprice && `${item.pdiscountprice} Ft`}
        </p>
      </NavLink>
    </li>
}

export default connect(mapStateToProps, mapDispatchToProps)(Products);
