const middlewareConfig = {
  interceptors: {
    request: [
      ({ getState, dispatch }, config) => {
        return config;
      },
    ],
    response: [
      {
        error: ({ dispatch }, error) => {
          cancelEventNotification(error);
          return Promise.reject(error);
        },
        success: ({ dispatch }, response) => {
          redirectToLocationUrl(response.headers.location);
          return response;
        },
      },
    ],
  },
  returnRejectedPromiseOnError: true,
};

const cancelEventNotification = (error) => {
  console.log(error);
};

const redirectToLocationUrl = (location) => {
  if (location) {
    window.location = location;
  }
};

export default middlewareConfig;
