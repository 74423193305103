import url_config from '../../util/url_config';

export const APP_ACTIONS = {
  INIT_DATA: '[APP] INIT_DATA',
  SELECT_SHOP: '[APP] SELECT_SHOP',
  SUCCESS: {
    INIT_DATA: '[APP] INIT_DATA_SUCCESS',
  },
  FAIL: {
    INIT_DATA: '[APP] INIT_DATA_FAIL',
  },
};

export const getInitData = () => {
  return {
    payload: {
      client: url_config.DEFAULT_CLIENT,
      request: {
        method: 'GET',
        url: url_config.GLOBAL.GET_INIT_DATA,
        params: {
          action: 'op_get_version',
        },
      },
    },
    type: APP_ACTIONS.INIT_DATA,
  };
};

export const selectShop = (id: string) => ({
  id,
  type: APP_ACTIONS.SELECT_SHOP,
});
