import * as React from 'react';
import refresh from '../../assets/images/refresh@2x.png';
import { NavLink, Redirect } from 'react-router-dom';
import { mapStateToProps, mapDispatchToProps } from './Pay.connect';
import { connect } from 'react-redux';
import { IPayProps } from './Pay.interface';
// import style from './Pay.module.less';

class Pay extends React.Component<IPayProps> {
  public interval: NodeJS.Timeout | undefined;
  constructor(props: IPayProps) {
    super(props);
  }

  public componentDidMount() {
    this.starPayment();
    this.startInterval();
  }

  public componentWillUnmount() {
    this.clearInterval();
  }

  public render() {
    if (this.props.order.length && this.props.order[0].payment_details[0].payment_id) {
      return (<Redirect to={{ pathname: '/landing' }} />);
    }
    return (
      <h3>
        <div className="loading loadingAnimation">
          <img src={refresh} /><br />
          Várakozás fizetésre...<br />
          <a
            onClick={() => this.starPayment()}
            className="icon ripple"
          >
            Fizetés újraindítása
          </a><br />
          <NavLink
            to="/landing"
            className="icon ripple"
          >
            Vissza a rendelésekhez
          </NavLink>
        </div>
      </h3>
    );
  }

  private getCurrentOrders = () => {
    this.clearInterval();
    this.startInterval();
  }

  private startInterval = () => {
    const { shop, userId } = this.props;
    if (userId && shop.blogid) {
      this.props.action.getCurrentOrders(shop.blogid, userId);
    }
    this.interval = setInterval(this.getCurrentOrders, 10000);
  }

  private clearInterval = () => {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  private starPayment() {
    const id = window.location.pathname.split('/')[2];
    const url = `https://okospincerpay.tk/${id}`;
    let open = window.open;
    if ((window as any).navigator.appInfo.identifier.indexOf('okospincer') >= 0) {
      open = (window as any).cordova.InAppBrowser.open;
    }
    const ref = open(url, '_blank', 'location=no');
    if (ref) {
      ref.addEventListener('loadstart', (event: any) => {
        if (event.url.indexOf('success') > 0) {
          ref.close();
        }
      });
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Pay);
