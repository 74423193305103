import * as React from 'react';
import getModuleLanguage from '../Dictionaries/Dictionaries';
let dictionary: any;
import style from './Product.module.less';
import refresh from '../../assets/images/refresh@2x.png';
import { mapStateToProps, mapDispatchToProps } from './Product.connect';
import { connect } from 'react-redux';
import { IProductProps, IProductState } from './Product.interface';
import { Image } from '../Image/Image';
import { IMinus, IToppingCategory, ITopping } from '../Category/Category.interface';
import { get } from 'lodash';
import { ICartItem } from '../Cart/Cart.interface';
import Toast from '../Toast/Toast';

class Product extends React.Component<IProductProps, IProductState> {
  constructor(props: IProductProps) {
    super(props);
    this.state = {
      expandedGroup: {},
      toppings: {},
      minuses: {},
      quantity: 1,
      price: 0,
    };
    dictionary = getModuleLanguage('product');
    if (props.shop && props.id) {
      props.action.getProduct(props.shop.blogid, props.id).then(() => {
        this.calculatePrice();
      });
    }
  }

  public render() {
    const {
      isLoading,
      product,
      minuses,
      shop,
      isLoggedIn,
      toppingCategories,
      toppings,
    } = this.props;
    return (
      <section className={style.product}>
        {isLoading &&
          <div className="loading loadingAnimation">
            <Image src={refresh} />
          </div>
        }
        <Image className="bigimage" src={product.kep_image} />
        <div className="proddesc">
          <h3 className="forcecolor">{product.prod_name}</h3>
          <h4>{this.state.price} Ft</h4>
          <p>{product.prod_description}</p>
        </div>
        {!isLoggedIn && dictionary.loginToOrder}
        {isLoggedIn &&
          <>
            <div className={style.prodorder}>
              <select className={style.prodquantity} onChange={this.setQuantity}>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
              </select>
              <a className="button ripple addtocart" onClick={this.addToCart}>
                {dictionary.addToCart}
              </a>
              {/* <a className="button transp backButton">Vissza</a> */}
            </div>
            {toppings.length > 0 &&
              <>
                <h6 className={style.header}>
                  {get(shop, 'topping_name', '') || dictionary.toppings}
                </h6>
                <div className={style.checklist}>
                  {(toppingCategories || [])
                    .filter((item: IToppingCategory) =>
                    toppings.some((topping: ITopping) => topping.topping_cat === `${item.id}`))
                    .map(this.mapToppingCategory)}
                </div>
              </>
            }
            {minuses.length > 0 &&
              <>
                <h6 className={style.header}>
                  {get(shop, 'minus_name', '') || dictionary.minuses}
                </h6>
                <div className={style.checklist}>
                  {minuses.map(this.mapMinus)}
                </div>
              </>
            }
          </>
        }
      </section >
    );
  }

  private setQuantity = (event: any) =>
    this.setState(
      { quantity: parseInt(event.target.value, 10) },
      () => this.calculatePrice(),
    )

  private mapMinus = (item: IMinus) =>
    <li className="checkbox" key={`${item.id}`}>
      <input
        type="checkbox"
        id={`minus_${item.id}`}
        value={item.price}
        onClick={this.toggleMinus(item)}
      />
      <label htmlFor={`minus_${item.id}`}>
        <b> - {item.name}</b>
        <small> ( -{item.price} Ft )</small>
      </label>
    </li>

  private mapToppingCategory = (category: IToppingCategory) =>
    <ul
      key={`category_${category.id}`}
      // tslint:disable-next-line: max-line-length
      className={`toppings ${category.mandatory && 'mandatory'} ${!this.state.expandedGroup[category.id] && style.collapsed}`}
    >
      <li className={style.title} onClick={this.toggleGroup(category.id)}>
        {`${category.name} ${category.mandatory && ' *'}`}
      </li>
      {this.props.toppings
        .filter((item: ITopping) => item.topping_cat === `${category.id}`)
        .map(this.mapTopping)}
    </ul>

  private mapTopping = (item: ITopping) => {
    const { name, type } = this.props.toppingCategories
      .filter((citem: IToppingCategory) => `${citem.id}` === item.topping_cat)[0];
    const elementType = type === 'singleselect' ? 'radio' : 'checkbox';
    return (<li className={elementType} key={`topping_${item.cid}`}>
      <input
        type={elementType}
        name={name || 'checkbox'}
        id={`topping_${item.cid}`}
        value={item.cprice}
        onChange={this.toggleTopping(item)}
      />
      <label htmlFor={`topping_${item.cid}`}>
        <b> + {item.cname}</b>
        <small> ( {item.cprice} Ft )</small>
      </label>
    </li>);
  }

  private toggleGroup = (id: number) =>
    () => this.setState({ expandedGroup: {
      ...this.state.expandedGroup,
      [id]: !this.state.expandedGroup[id],
    }})

  private toggleTopping = (topping: ITopping) =>
    () => {
      this.setState(
        {
          toppings: {
            ...this.state.toppings,
            [topping.cid]: !this.state.toppings[topping.cid] ?
              parseInt(topping.cprice, 10) : 0,
          },
        },
        () => this.calculatePrice(),
      );
    }

  private toggleMinus = (minus: IMinus) =>
    () => {
      this.setState(
        {
          minuses: {
            ...this.state.minuses,
            [minus.id]: !this.state.minuses[minus.id] ?
              parseInt(minus.price, 10) : 0,
          },
        },
        () => this.calculatePrice(),
      );
    }

  private calculatePrice = () => {
    const { product } = this.props;
    const { prod_discountprice, prod_price, prod_type, parent_type } = product;
    let sum = parseInt(prod_discountprice || prod_price, 10);
    let extras: number = 0;
    Object.keys(this.state.toppings).forEach((item: string) => {
      extras += this.state.toppings[item];
    });
    let type = prod_type;
    if (type === 'inherit') {
      type = parent_type;
    }
    Object.keys(this.state.minuses).forEach((item: string) => {
      const minPrice = this.state.minuses[item];
      switch (type) {
        case 'simple':
          extras -= minPrice;
          break;
        case 'minimalprice':
          extras = (extras > minPrice ? extras - minPrice : 0);
          break;
        case 'onlytopping':
          break;
      }
    });
    sum += extras;
    sum *= this.state.quantity;
    this.setState({ price: sum });
  }

  private addToCart = () => {
    const some = Array.prototype.some;
    const every = Array.prototype.every;
    const mandatorElements = document.querySelectorAll('ul.mandatory');
    const hasUnfilledMandatory = some.call(mandatorElements, (group: any) =>
    every.call(
      group.querySelectorAll('li input'),
      item => !item.checked,
      ));

    if (hasUnfilledMandatory) {
      Toast(dictionary.fillMandatoryFields);
      return false;
    }

    const data: ICartItem = {
      pid: parseInt(this.props.id, 10),
      quantity: this.state.quantity,
      package: 0,
      toppings: this.getMappedToppings(),
      minuses: this.getMappedMinuses(),
      price: (this.state.price / this.state.quantity),
    };
    this.props.action.addToCart(data);
    Toast(dictionary.addedToCart);
  }

  private getMappedToppings = (): object[] => {
    return Object.keys(this.state.toppings)
    .filter((key: string) => this.state.toppings[key])
    .map((key: string) => ([
      key,
      this.state.toppings[key],
    ]));
  }

  private getMappedMinuses = (): object[] => {
    return Object.keys(this.state.minuses)
    .filter((key: string) => this.state.minuses[key])
    .map((key: string) => ([
      key,
      this.state.minuses[key],
    ]));
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Product);
