import React from 'react';
import emptyImage from '../../assets/images/no-image.jpg';

// tslint:disable-next-line: variable-name
export const Image = (props: any) => {
  const fallBack = (error: any) => {
    error.target.onerror = null;
    error.target.src = emptyImage;
  };

  return <img {...props} src={props.src || emptyImage} onError={fallBack} />;
};
