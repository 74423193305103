import * as React from 'react';
import ReactDOMServer from 'react-dom/server';
import style from '../History.module.less';
import getModuleLanguage from '../../Dictionaries/Dictionaries';
import { IHistoryProps } from '../History.interface';
import { formatMoney } from '../../Landing/Landing';
import { Image } from '../../Image/Image';
import question from '../../../assets/images/question.png';
let dictionary: any;

// tslint:disable-next-line: variable-name
const Slide3 = (props: IHistoryProps) => {
  const { history } = props;
  const date = new Date();
  dictionary = getModuleLanguage('history');
  const content = dictionary.slide3.spending
  .replace(
    '%year%',
    ReactDOMServer.renderToString(<span className={style.year}>{date.getFullYear()}</span>),
  )
  .replace(
    '%average%',
    ReactDOMServer.renderToString(
      <span className="monthspend">
        {formatMoney(Math.round(history.sum / (date.getMonth() + 1)), 0)}
      </span>,
    ),
  );
  const average = dictionary.slide3.average
  .replace(
    '%average%',
    ReactDOMServer.renderToString(
      <span>
        {formatMoney(Math.round(history.sum / history.orders.length), 0)}
      </span>,
    ),
  );
  return (
    <div className={`${style.slide} tcenter slide3`}>
      <h1>
        <span>{formatMoney(history.sum, 0)}</span>
        <strong>{dictionary.slide3.money}</strong>
      </h1>
      <h4>{dictionary.slide3.howMuch}</h4>
      <p dangerouslySetInnerHTML={{ __html: content }} />
      <h3 dangerouslySetInnerHTML={{ __html: average }} />
      <Image src={question} className="icon content-boxed" />
      <p className={style.info} dangerouslySetInnerHTML={{ __html: dictionary.slide3.info }} />
    </div>
  );
};

export default Slide3;
