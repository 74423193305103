import url_config from '../../util/url_config';

export const SEARCH_ACTIONS = {
  GET_SHOPS: '[SHOPS] GET_SHOPS',
  SUCCESS: {
    GET_SHOPS: '[SHOPS] GET_SHOPS_SUCCESS',
  },
  FAIL: {
    GET_SHOPS: '[SHOPS] GET_SHOPS_FAIL',
  },
};

export const getShops = () => {
  return {
    payload: {
      client: url_config.DEFAULT_CLIENT,
      request: {
        method: 'GET',
        url: url_config.GLOBAL.GET_INIT_DATA,
        params: {
          action: 'op_get_stores',
        },
      },
    },
    type: SEARCH_ACTIONS.GET_SHOPS,
  };
};
