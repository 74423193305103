import * as React from 'react';
import { NavLink } from 'react-router-dom';
import style from './Search.module.less';
import search from '../../assets/images/search@2x.png';
import filter from '../../assets/images/filter@2x.png';
import refresh from '../../assets/images/refresh@2x.png';
import shop_info from '../../assets/images/shop_info@2x.png';
import { mapStateToProps, mapDispatchToProps } from './Search.connect';
import { connect } from 'react-redux';
import { ISearchProps, IShop } from './Search.interface';
import { headLines } from './Search.contants';
import getModuleLanguage from '../Dictionaries/Dictionaries';
let dictionary: any;

class Search extends React.Component<ISearchProps> {
  private storedHeadline: string = '';
  constructor(props: ISearchProps) {
    super(props);
    dictionary = getModuleLanguage('search');
    this.props.action.getShops();
  }

  public render() {
    this.storedHeadline = '';
    const { isLoading, shops } = this.props;
    return (
      <section className={`${style.search}`}>
        {isLoading &&
          <div className="loading loadingAnimation">
            <img src={refresh} />
          </div>
        }
        <div className={style.filter}>
          <div className={`${style.headline}`}>
            <img src={search} className={style.searchicon} />
            <input type="text" className={style.namefilter} />
            <img src={filter} className={style.filtericon} />
          </div>
          <div className={style.storefilters} />
        </div>
        <div className={`stickylist ${style.stickylist}`}>
          <ul>
              {shops.map(this.mapShops)}
          </ul>
        </div>
      </section>
    );
  }

  private mapShops = (item: IShop) => {
    return (
      <React.Fragment key={`shop-${item.blogid}`}>
        {this.getHeadline(item)}
        <li>
          <NavLink to={`category/${item.blogid}`} onClick={this.selectshop(item.blogid)}>
            <div className="shop_image" style={{ backgroundImage: `url(${item.image})` }} />
            <div className={`desc ${style.desc}`}>
              <b>{item.blogname}</b><br />
              {item.address || ''}
              <span>{this.getNotice(item)}</span>
            </div>
          </NavLink>
          <NavLink to={`shop/${item.blogid}`} className="info">
            <img src={shop_info} />
          </NavLink>
        </li>
      </React.Fragment>
    );
  }

  private getHeadline = (item: IShop) => {
    let headLine = null;
    if (this.storedHeadline !== item.isOpen) {
      this.storedHeadline = item.isOpen;
      const statusclass = item.isOpen !== '0' ? 'open' : '';
      headLine = (
        <strong
          className={statusclass}
          dangerouslySetInnerHTML={{ __html: headLines(dictionary)[item.isOpen] }}
        />
      );
    }
    return headLine;
  }

  private getNotice = (item: IShop) => {
    let notice = '';
    const { deliveries } = item;
    if (item.isOpen !== '0') {
      const delPrice = Math.min.apply(
        null,
        Object.keys(deliveries).map(del => deliveries[del].price),
      );
      if (delPrice !== Infinity) {
        notice = dictionary.deliveryFrom.replace('%price%', delPrice);
      }
    } else if (item.blog_open) {
      let day = new Date().getDay() - 1;
      day = day === -1 ? 6 : day;
      day *= 2;
      const currentOpen = item.blog_open[day];
      if (!currentOpen || (currentOpen === '00:00' && item.blog_open[day + 1] === '00:00')) {
        notice = dictionary.closedToday;
      } else {
        notice = dictionary.openAt.replace('%open%', item.blog_open[day]);
      }
    }
    return notice;
  }

  private selectshop = (shopid: string) =>
    () => this.props.action.selectShop(shopid)
}

export default connect(mapStateToProps, mapDispatchToProps)(Search);
