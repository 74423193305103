import * as React from 'react';
import ReactSwipe from 'react-swipe';
import { NavLink } from 'react-router-dom';
import { slidesConfig } from './Intro.config';
import style from './Intro.module.less';
import { Slide } from './Slide/Slide';
import { ISlideProps } from './Slide/Slide.interface';
import { IIntroState } from './Intro.interface';
import getModuleLanguage from '../Dictionaries/Dictionaries';
import { Image } from '../Image/Image';
let dictionary: any;
import hun from '../../assets/images/hun.png';
import en from '../../assets/images/en.png';

class Intro extends React.Component<{}, IIntroState> {
  constructor(props: {}) {
    super(props);
    dictionary = getModuleLanguage('intro');
    this.state = {
      currentSlide: 0,
    };
  }

  public render() {
    const languages = [
      {
        src: hun,
        onClick: () => this.changeLanguage('hu'),
      },
      {
        src: en,
        onClick: () => this.changeLanguage('en'),
      },
    ];
    return (
      <section className={`${style.intro}`}>
        <div className={`${style.slides}`}>
          <ReactSwipe
            swipeOptions={{
              transitionEnd: (index: number) => this.setState({ currentSlide: index }),
              continuous: true,
              auto: 3000,
              speed: 500,
              startSlide: this.state.currentSlide,
            }}
            childCount={slidesConfig(dictionary).length}
          >
            {slidesConfig(dictionary).map((item: ISlideProps, index: number) =>
              <Slide key={`slide-${index}`} {...item} />)}
          </ReactSwipe>
          <ul className={style.dots}>
            {
              // tslint:disable-next-line: variable-name
              Array(slidesConfig(dictionary).length).fill(0).map((_item: number, index: number) => (
                <li
                  className={index === this.state.currentSlide ? style.active : ''}
                  key={`slide-${index}`}
                  onClick={() => this.setState({ currentSlide: index })}
                />
              ))
            }
          </ul>
        </div>
        <div className={style.clear}>
          <NavLink className={`${style.button} button ripple`} to={'login'}>
            { dictionary.login }
          </NavLink>
          <NavLink className={`${style.button} button ripple`} to={'start'}>
            { dictionary.browseWithoutLogin }
          </NavLink>
        </div>
        <div className={style.language}>
          {languages.map((item: any, index: number) =>
            <Image key={`language-${index}`} {...item} />)}
        </div>
      </section>
    );
  }

  private changeLanguage = (language: string) => {
    localStorage.setItem('language', language);
    window.location.reload();
  }
}

export default Intro;
