import * as React from 'react';
import style from './Toast.module.less';
import ReactDOM from 'react-dom';

// tslint:disable-next-line: variable-name
const Toast = (content: string, timeOut: number = 2000) => {
  ReactDOM.render(
    <div
      className={`${style.toast}`}
      dangerouslySetInnerHTML={{ __html: content }}
    />,
    document.getElementById('toast'),
    () => {
      setTimeout(
        () => {
          document.querySelectorAll('#toast > div').forEach(item =>
            item.className += ` ${style.show}`);
        },
        50,
      );
    },
  );
  if (navigator.vibrate) {
    navigator.vibrate(50);
  }
  setTimeout(
    () => {
      const toastContainer = document.getElementById('toast');
      if (toastContainer) {
        ReactDOM.unmountComponentAtNode(toastContainer);
      }
    },
    timeOut,
  );
};

export default Toast;
