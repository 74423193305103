import * as React from 'react';
import style from '../History.module.less';
import getModuleLanguage from '../../Dictionaries/Dictionaries';
import { IHistoryProps, IHistoryOrder } from '../History.interface';
import { IShop } from '../../Search/Search.interface';
import { formatMoney } from '../../Landing/Landing';
let dictionary: any;

// tslint:disable-next-line: variable-name
const Slide5 = (props: IHistoryProps) => {
  const { history, shops } = props;
  dictionary = getModuleLanguage('history');
  return (
    <div className={`${style.slide} tcenter ${style.slide5}`}>
      <h1>
        <span className={style.percent}>{history.orders.length}</span>
        <strong>{dictionary.slide5.orders}</strong>
      </h1>
      <h4>{dictionary.slide5.what}</h4>
      {history.orders.map((item: IHistoryOrder) => {
        const shop = shops
          .filter((sitem: IShop) => sitem.blogid === item.shop)[0];
        return (
          <div key={`order-${item.id}`} className={style.order}>
            <div className={style.oid}>{`#${item.id}`}</div>
            <div className={style.date}>{item.date}</div>
            <div className={style.shop}>{shop.blogname}</div>
            <div className={style.amount}>
              {dictionary.slide5.amount}: {formatMoney(item.amount, 0, '.', ',')} Ft
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Slide5;
