import { clone } from 'lodash';
import { SEARCH_ACTIONS } from './Search.action';
import { IShop } from './Search.interface';

const defaultAppState = {
  isLoading: true,
  shops: [],
};

// tslint:disable-next-line: variable-name
export const Search = (
  state = clone(defaultAppState),
  action: any,
) => {
  switch (action.type) {
    case SEARCH_ACTIONS.GET_SHOPS:
      return defaultAppState;
    case SEARCH_ACTIONS.SUCCESS.GET_SHOPS:
      return {
        isLoading: false,
        shops: action.payload.data
          .map((shop: IShop) => ({ ...shop, isOpen: shop.isOpen || '0' }))
          .sort((shop: IShop, prevShop: IShop) =>
            prevShop.isOpen.localeCompare(shop.isOpen)),
      };
    case SEARCH_ACTIONS.FAIL.GET_SHOPS:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
