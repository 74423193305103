import { getCurrentOrders } from '../Landing/Landing.action';
import { IShop } from '../Search/Search.interface';

export const mapStateToProps = ({ Landing, Login, Search, App }: any) => ({
  order: Landing.orders.filter((order: any) =>
    order[0].payment_id === window.location.pathname.split('/')[2])[0] || [],
  userId: Login.userData.id,
  shop: Search.shops.filter((shop: IShop) =>
    shop.blogid === App.selectedShop)[0],
});

export const mapDispatchToProps = (dispatch: any) => ({
  action: {
    getCurrentOrders: (blogId: string, userId: string) =>
      dispatch(getCurrentOrders(blogId, userId)),
  },
});
