import { ILanguage } from '../Dictionaries.interface';

export const dictionary: ILanguage = {
  notFound: {
    notFoundMessage: 'OUCH! Something went wrong...',
  },
  addresses: {
    title: 'Address title (eg. Home)',
    zip: 'ZIP',
    city: 'City',
    street: 'Street',
    number: 'Number',
    bell: 'Bell',
    company: 'Company name',
    floor: 'Floor / door',
    selectCity: 'Select city',
    saveAddress: 'Save address',
    cancel: 'Cancel',
    deleteAddress: 'Remove address',
    noAddresses: 'You have no saved addresses.',
    saveSuccessful: 'Successful saving!',
    saveFailed: 'Unsuccessful saving',
  },
  cart: {
    delivery: 'Delivery',
    takeway: 'Take away',
    local: 'Local order',
    orderType: 'Order type',
    deliveryAddress: 'Select Address',
    billingAddress: 'Select Address',
    paymentMethod: 'Payment method',
    message: 'Message',
    cupon: 'Cupon code',
    send: 'Send',
    cancel: 'Cancel',
    closeOrder: 'Order',
    emptyOrder: 'Empty cart',
    tableNumber: 'Table number',
    deliveryAddressLabel: 'Delivery address',
    billingAddressLabel: 'Billing address',
    takewayTime: 'Take away time',
    enterCuponCode: 'Enter code',
    enterMessage: 'Enter message',
    minimalAmount: 'Minimal order amount:',
    removeFromCartConfirm: 'Do you really want to remove from cart?',
    select: 'Select',
    table: 'table',
    deliveryPrice: 'Delivery price',
    discount: 'Discount',
    cartIsEmpty: 'Cart is empty',
    summary: 'Summary',
    timeNotAvailable: 'Not available',
  },
  history: {
    slide1: {
      howManyTimes: 'How many times?',
      occasionPerMonth: 'occasions/month',
      occasions: 'OCCASIONS',
      used: 'In %year% you used OkosPincér %count% times, which is',
    },
    slide2: {
      where: 'Where?',
      mostFrequent: 'In %year% by the most occasions and orders',
      yourFavoritePlace: 'is your favorite place.',
    },
    slide3: {
      money: 'FORINTS',
      howMuch: 'How much?',
      spending: 'In %year% you spent %average% forints per month, which is ',
      average: '%average% forints per occasion.',
      info: `Each user who spends <strong>250.000.- forints</strong>
      will get reward!`,
    },
    slide4: {
      favorite: 'FAVORITE',
      buddy: 'BUDDY',
      place: 'PLACES',
      how: 'How?',
      placesAndFriends: 'In %year% you visited %stores% places, added %favorites% to favorites and',
      friendsUsingOP: 'friends are using OkosPincér.',
    },
    slide5: {
      orders: 'Orders',
      what: 'What?',
      amount: 'Amount',
    },
  },
  intro: {
    login: 'Login',
    register: 'Register',
    browseWithoutLogin: 'Browse without login',
    slide1Title: 'Smart order',
    slide1Content: 'Browse foods and order with your phone',
    slide2Title: 'Smart reservation',
    slide2Content: 'Reserve a table with only few clicks',
    slide3Title: 'Smart choice',
    // tslint:disable-next-line: max-line-length
    slide3Content: 'Browse discounts before selecting a place.',
    slide4Title: 'Favorites',
    // tslint:disable-next-line: max-line-length
    slide4Content: 'Collect your favorite places to quickly re-order from them.',
  },
  landing: {
    status1: 'A rendelést megkaptuk és készítjük...',
    status2: 'Elkészült a rendelés, már csomagoljuk...',
    status3: 'Átadtuk a rendelést a futárnak',
    status4: 'A futár a rendeléssel hamarosan odaér...',
    toProducts: 'A termékekhez',
    ordersInProgress: 'Folyamatban lévő rendelések',
    estimatedRemainingTime: 'Becsült hátralévő idő',
  },
  loading: {
    loadingModules: 'Loading application...',
  },
  login: {
    loginDescription: `<br />
    <strong>This application need access</strong> for your public profile:<br />
    - frieds list,<br />
    - profile data,<br />
    - and profile picture.<br /><br />
    This application <strong>will not post</strong> on your wall without your approval,
    and <strong> we respect your privacy.</strong>
    We won't give your data to third party.`,
    loginWithFacebook: 'Facebook Login',
    loginWithGoogle: 'Google Login',
  },
  menu: {
    favorite: 'Favorite',
    buddy: 'Buddy',
    occasion: 'Occasion',
    search: 'Search',
    products: 'Products',
    orders: 'Orders',
    profile: 'Profile',
    history: 'History',
    addresses: 'Addresses',
    faq: 'FAQ',
  },
  product: {
    minuses: 'Minuses',
    toppings: 'Toppings',
    addToCart: 'Add to cart',
    loginToOrder: 'Please login to order!',
    fillMandatoryFields: 'Marked (*) categories are mandatory!',
    addedToCart: 'Product added to cart',
  },
  settings: {
    profileData: 'Profile',
    name: 'Name',
    email: 'Email',
    phone: 'Phone number',
    language: 'Language',
    hun: 'Magyar',
    eng: 'English',
    save: 'Save',
    saveSuccess: 'Successful saving!',
    saveFailed: 'Unsuccessful saving!',
    fbsecurity: `Same in English: Az adataid nem adjuk át meghatalmazásod nélkül semmilyen harmadik félnek, és nem tesszük sehol nyilvánossá.
    Továbbá biztonságosan tároljuk és csak az alkalmazás kényelmes működéséhez használjuk.`,
    bannedText: 'We are sorry,<br />but your account has been disabled by %shopname% restaurant.',
    contactWithShop: `In order to resolve this, please <strong>get in touch</strong> with them,
    as soon as it is resolved you can use OkosPincér again.`,
  },
  shop: {
    garden: 'Garden',
    privateRoom: 'Private room',
    delivery: 'Delivery',
    menu: 'Menu',
    liveMusic: 'Live music',
    sportsLive: 'Live sports',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
    closed: 'Closed',
    selectShop: 'Products',
  },
  start: {
    noAdresses: 'Please add delivery address!',
    howItWorks: 'How this application works? Why is it useful for you?',
    suggestPlace: 'Recommend a place!',
    selectCity: 'Find a place in <span>%city%</span>',
  },
  scroller: {
    select: 'Select',
    cancel: 'Cancel',
  },
  search: {
    currentlyClosed: 'Currently closed',
    currentlyOpen: 'Currently open',
    deliveryFrom: 'Delivery from %price% HUF',
    closedToday: 'Closed today',
    openAt: 'Open at %open%',
  },
};
