import * as React from 'react';

class Footer extends React.Component {
  public render() {
    return (
      <footer />
    );
  }
}

export default Footer;
