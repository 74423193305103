import url_config from '../../util/url_config';

export const FAQ_ACTIONS = {
  GET_FAQ: '[FAQ] GET_FAQ',
  SUCCESS: {
    GET_FAQ: '[FAQ] GET_FAQ_SUCCESS',
  },
  FAIL: {
    GET_FAQ: '[FAQ] GET_FAQ_FAIL',
  },
};

export const getFaq = () => {
  return {
    payload: {
      client: url_config.DEFAULT_CLIENT,
      request: {
        method: 'GET',
        url: url_config.GLOBAL.GET_INIT_DATA,
        params: {
          action: 'op_get_faq',
        },
      },
    },
    type: FAQ_ACTIONS.GET_FAQ,
  };
};
