import * as React from 'react';
import ReactDOMServer from 'react-dom/server';
import style from '../History.module.less';
import getModuleLanguage from '../../Dictionaries/Dictionaries';
import { IHistoryProps } from '../History.interface';
let dictionary: any;

// tslint:disable-next-line: variable-name
const Slide2 = (props: IHistoryProps) => {
  const { history } = props;
  const date = new Date();
  dictionary = getModuleLanguage('history');
  const content = dictionary.slide2.mostFrequent
  .replace(
    '%year%',
    ReactDOMServer.renderToString(<span className={style.year}>{date.getFullYear()}</span>),
  );
  return (
    <div className={`${style.slide} tcenter slide2`}>
      <h1>
        <span className={style.percent}>{history.mostfrequently[1]}</span>
        <strong>{history.mostfrequently[0]}</strong>
      </h1>
      <h4>{dictionary.slide2.where}</h4>
      <p dangerouslySetInnerHTML={{ __html: content }} />
      <h3>{history.mostfrequently[0]}</h3>
      <p>{dictionary.slide2.yourFavoritePlace}</p>
    </div>
  );
};

export default Slide2;
