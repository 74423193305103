import { clone } from 'lodash';

const defaultMenuState = {
  displayMenu: false,
};

// tslint:disable-next-line: variable-name
export const History = (
  state = clone(defaultMenuState),
  action: any,
) => {
  switch (action.type) {
    default:
      return state;
  }
};
