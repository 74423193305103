import * as React from 'react';
import ReactDOMServer from 'react-dom/server';
import style from '../History.module.less';
import getModuleLanguage from '../../Dictionaries/Dictionaries';
import { IHistoryProps } from '../History.interface';
let dictionary: any;

// tslint:disable-next-line: variable-name
const Slide4 = (props: IHistoryProps) => {
  const date = new Date();
  const { history, friends, favorites } = props;
  dictionary = getModuleLanguage('history');
  const content = dictionary.slide4.placesAndFriends
  .replace(
    '%year%',
    ReactDOMServer.renderToString(<span className={style.year}>{date.getFullYear()}</span>),
  )
  .replace(
    '%stores%',
    ReactDOMServer
      .renderToString(<span className="place">{Object.keys(history.stores).length}</span>),
  )
  .replace(
    '%favorites%',
    ReactDOMServer.renderToString(<span className="favourite">{favorites}</span>),
  );
  return (
    <div className={`${style.slide} tcenter slide4`}>
      <div className={style.circles}>
        <div className={`${style.circle} circle`}>
          <span className="favourite ins">{favorites}</span>
          <strong>{dictionary.slide4.favorite}</strong>
        </div>
        <div className={`${style.circle} ${style.midc} circle`}>
          <span className="buddy ins">{friends}</span>
          <strong>{dictionary.slide4.buddy}</strong>
        </div>
        <div className={`${style.circle} circle`}>
          <span className="place">{Object.keys(history.stores).length}</span>
          <strong>{dictionary.slide4.place}</strong>
        </div>
      </div>
      <h4>{dictionary.slide4.how}</h4>
      <p dangerouslySetInnerHTML={{ __html: content }} />
      <h3><span className="buddy">{friends}</span> </h3>
      <p>{dictionary.slide4.friendsUsingOP}</p>
    </div>
  );
};

export default Slide4;
