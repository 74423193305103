import { getProduct, addToCart } from './Product.action';
import { IMinus, ITopping } from '../Category/Category.interface';
import { IShop } from '../Search/Search.interface';
import { ICartItem } from '../Cart/Cart.interface';

export const mapStateToProps = ({ Product, Category, Search, App, Login }: any) => ({
  product: Product.product,
  isLoading: Product.isLoading,
  toppingCategories: Category.toppingCategories,
  minuses: Category.minuses.filter((item: IMinus) =>
    (Product.product.prod_minus || [''])[0].indexOf(`${item.id}`) >= 0),
  toppings: Category.toppings.filter((item: ITopping) =>
    (Product.product.prod_topping || [''])[0].indexOf(`${item.cid}`) >= 0),
  shop: Search.shops.filter((shop: IShop) =>
    shop.blogid === App.selectedShop)[0],
  isLoggedIn: Object.keys(Login.userData).length > 0,
});

export const mapDispatchToProps = (dispatch: any) => ({
  action: {
    getProduct: (store: string, id: string) => dispatch(getProduct(store, id)),
    addToCart: (product: ICartItem) => dispatch(addToCart(product)),
  },
});
