import * as React from 'react';
import ReactDOMServer from 'react-dom/server';
import style from './Settings.module.less';
import getModuleLanguage from '../Dictionaries/Dictionaries';
let dictionary: any;
import { ISettingsProps, IProfile } from './Settings.interface';
import { mapStateToProps, mapDispatchToProps } from './Settings.connect';
import { connect } from 'react-redux';
import { Image } from '../Image/Image';
import question from '../../assets/images/question.png';
import { get } from 'lodash';
import Toast from '../Toast/Toast';
import { IShop } from '../Search/Search.interface';

class Settings extends React.Component<ISettingsProps, IProfile> {
  constructor(props: ISettingsProps) {
    super(props);
    this.state = {
      id: props.user.id,
      name: props.user.name,
      email: props.user.email,
      phone: props.user.phone,
      language: props.user.language,
    };
    dictionary = getModuleLanguage('settings');
  }

  public render() {
    const { name, email, phone, id, language } = this.state;
    const { banned } = this.props.user;
    const isBanned = banned !== '0';
    const props = {
      type: 'text',
      className: style.value,
      required: true,
    };
    const bannedShop = this.props.shops.filter((item: IShop) => item.blogid === banned)[0] || {};
    const bannedContent = dictionary.bannedText
    .replace(
      '%shopname%',
      ReactDOMServer.renderToString(<span>{bannedShop.blogname || ''}</span>),
    );
    return (
      <section className={`${style.settings}`}>
        {!isBanned &&
          <>
            <div className={`${style.avatar} animated a2s d2`}>
              <Image
                className={`${style.profile}`}
                src={`https://graph.facebook.com/${id}/picture?width=200&height=200`}
              />
              <div className="name">{name}</div>
            </div>
            <div id="pulser" className="pulse">
              <div className="pulse pulse1" />
              <div className="pulse pulse2" />
              <div className="pulse pulse3" />
              <div className="pulse pulse4" />
            </div>
            <div className={style.fbdata}>
              {dictionary.profileData}
            </div>
            <div className={style.userdata}>
              <div className={style.name}>
                <input {...props} defaultValue={name} onChange={this.updateValue('name')} />
                <label>{dictionary.name}</label>
              </div>
              <div className={style.email}>
                <input {...props} defaultValue={email} onChange={this.updateValue('email')} />
                <label>{dictionary.email}</label>
              </div>
              <div className={style.phone}>
                <input {...props} defaultValue={phone} onChange={this.updateValue('phone')} />
                <label>{dictionary.phone}</label>
              </div>
              <div className={style.phone}>
                <select onChange={this.updateValue('language')}>
                  <option value="hu" selected={language === 'hu'}>{dictionary.hun}</option>
                  <option value="en" selected={language === 'en'}>{dictionary.eng}</option>
                </select>
                <label>{dictionary.language}</label>
              </div>
              <button className={style.saveProfile} onClick={this.saveProfile}>
                {dictionary.save}
              </button>
            </div>
            <p className={style.fbsecurity}>
              {dictionary.fbsecurity}
            </p>
          </>
        }
        {isBanned &&
          <>
            <div
              className={`${style.sefbdatattings} ${style.banned}`}
              dangerouslySetInnerHTML={{ __html: bannedContent }}
            />
            <img src={question} className={`${style.icon} ${style.banned}`} />
            <div
              className={`${style.fbsecurity} ${style.banned}`}
              dangerouslySetInnerHTML={{ __html: dictionary.contactWithShop }}
            />
          </>
        }
      </section>
    );
  }

  private updateValue = (label: string) =>
    (event: any) => {
      this.setState({ [label]: event.target.value });
    }

  private saveProfile = () => {
    this.props.action.saveProfile(this.state)
    .then((response: any) => {
      const success = get(response, 'payload.data.success', false);
      if (success) {
        localStorage.setItem('language', this.state.language);
        dictionary = getModuleLanguage('settings');
        this.setState(this.state);
      }
      Toast(success ? dictionary.saveSuccess : dictionary.saveFailed);
    });
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
