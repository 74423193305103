import { fbLogin, setLoading, setFriends } from './Login.action';
import { IFriend } from '../Menu/Menu.interface';
import { getHistory } from '../History/History.action';
import { getShops } from '../Search/Search.action';

export const mapStateToProps = ({ Login }: any) => ({
  isLoading: Login.isLoading,
  userData: Login.userData,
});

export const mapDispatchToProps = (dispatch: any) => ({
  action: {
    fbLogin: (data: any) => dispatch(fbLogin(data)),
    setFriends: (friends: IFriend[]) => dispatch(setFriends(friends)),
    setLoading: (value: boolean) => dispatch(setLoading(value)),
    getHistory: (user: string) => dispatch(getHistory(user)),
    getShops: () => dispatch(getShops()),
  },
});
