import url_config from '../../util/url_config';

export const LANDING_ACTIONS = {
  GET_CURRENT_ORDERS: '[LANDING] GET_CURRENT_ORDERS',
  SUCCESS: {
    GET_CURRENT_ORDERS: '[LANDING] GET_CURRENT_ORDERS_SUCCESS',
  },
  FAIL: {
    GET_CURRENT_ORDERS: '[LANDING] GET_CURRENT_ORDERS_FAIL',
  },
};

export const getCurrentOrders = (blogId: string, userId: string) => {
  return {
    payload: {
      client: url_config.DEFAULT_CLIENT,
      request: {
        method: 'GET',
        url: url_config.GLOBAL.GET_INIT_DATA,
        params: {
          userId,
          blogId,
          action: 'op_get_current_orders',
        },
      },
    },
    type: LANDING_ACTIONS.GET_CURRENT_ORDERS,
  };

};
