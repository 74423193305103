import url_config from '../../util/url_config';

export const HISTORY_ACTIONS = {
  GET_HISTORY: '[HISTORY] GET_HISTORY',
  SUCCESS: {
    GET_HISTORY: '[HISTORY] GET_HISTORY_SUCCESS',
  },
  FAIL: {
    GET_HISTORY: '[HISTORY] GET_HISTORY_FAIL',
  },
};

export const getHistory = (user: string) => {
  return {
    payload: {
      client: url_config.DEFAULT_CLIENT,
      request: {
        method: 'GET',
        url: url_config.GLOBAL.GET_INIT_DATA,
        params: {
          user,
          action: 'op_user_statistics',
        },
      },
    },
    type: HISTORY_ACTIONS.GET_HISTORY,
  };
};
