import { clone } from 'lodash';
import { APP_ACTIONS } from './App.action';

const defaultAppState = {
  isLoading: true,
  version: '',
  minimumVersion: '',
  selectedShop: '0',
};

// tslint:disable-next-line: variable-name
export const App = (
  state = clone(defaultAppState),
  action: any,
) => {
  switch (action.type) {
    case APP_ACTIONS.SELECT_SHOP:
      return {
        ...state,
        selectedShop: action.id,
      };
    case APP_ACTIONS.INIT_DATA:
      return defaultAppState;
    case APP_ACTIONS.FAIL.INIT_DATA:
      return {
        ...defaultAppState,
        isLoading: false,
      };
    case APP_ACTIONS.SUCCESS.INIT_DATA:
      return {
        ...state,
        isLoading: false,
        version: action.payload.data.data,
        minimumVersion: action.payload.data.minimumVersion,
      };
    default:
      return state;
  }
};
