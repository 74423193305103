import { clone, isEqual, get } from 'lodash';
import { CART_ACTIONS } from './Cart.action';
import { ICartItem } from './Cart.interface';
import { APP_ACTIONS } from '../App/App.action';

const defaultAppState: any = {
  isLoading: false,
  cart: {
    sum: 0,
    deliveryPrice: 0,
    paymentMethod: 0,
    deliveryAddress: {},
    billingAddress: {},
    orderType: '0',
    table: '1',
    message: '',
    deliveryTime: '',
    showScroller: '',
    elements: [],
    isCloseDisabled: true,
    cartItems: [],
    cupon: '',
    cuponData: {
      code: '',
      discount: 0,
    },
  },
};

// tslint:disable-next-line: variable-name
export const Cart = (
  state = clone(defaultAppState),
  action: any,
) => {
  switch (action.type) {
    case CART_ACTIONS.CLOSE_ORDER:
      return {
        ...state,
        isLoading: true,
      };
    case CART_ACTIONS.FAIL.CLOSE_ORDER:
      return {
        ...state,
        isLoading: false,
      };
    case CART_ACTIONS.SUCCESS.CLOSE_ORDER:
      const success = get(action, 'payload.data.success', false);
      return {
        ...(success ? defaultAppState : state),
        isLoading: false,
      };
    case CART_ACTIONS.VALIDATE_CUPON:
      return {
        ...state,
        isLoading: true,
      };
    case CART_ACTIONS.FAIL.VALIDATE_CUPON:
      return {
        ...state,
        isLoading: false,
      };
    case CART_ACTIONS.SUCCESS.VALIDATE_CUPON:
      const amount = get(action, 'payload.data.amount', false);
      const code = get(action, 'payload.data.code', false);
      return {
        isLoading: false,
        cart: {
          ...state.cart,
          cuponData: {
            code,
            discount: amount,
          },
        },
      };
    case CART_ACTIONS.ADD_TO_CART:
      return {
        ...state,
        cart: {
          ...state.cart,
          cartItems: [
            ...updateCartItem(state.cart.cartItems, action.product),
          ],
        },
      };
    case CART_ACTIONS.UPDATE_CART:
      return {
        ...state,
        cart: {
          ...state.cart,
          [action.field]: action.value,
        },
      };
    case CART_ACTIONS.REMOVE_FROM_CART:
      return {
        ...state,
        cart: {
          ...state.cart,
          cartItems: [
            ...state.cart.cartItems
              // tslint:disable-next-line: variable-name
              .filter((_item: ICartItem, index: number) => index !== action.index),
          ],
        },
      };
    case APP_ACTIONS.SELECT_SHOP:
      return {
        ...state,
        cart: {
          ...state.cart,
          cartItems: [],
        },
      };
    default:
      return state;
  }
};

const updateCartItem = (cartItems: ICartItem[], newItem: ICartItem) => {
  let updated = false;
  const newItems = cartItems.map((item: ICartItem) => {
    if (
      item.pid === newItem.pid
      && isEqual(item.toppings, newItem.toppings)
      && isEqual(item.minuses, newItem.minuses)
    ) {
      updated = true;
      return {
        ...item,
        quantity: item.quantity + newItem.quantity,
      };
    }
    return item;
  });
  if (!updated) {
    newItems.push(newItem);
  }
  return newItems;
};
