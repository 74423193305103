import { ILanguage } from '../Dictionaries.interface';

export const dictionary: ILanguage = {
  notFound: {
    notFoundMessage: 'Az ellen nem véd...',
  },
  addresses: {
    title: 'Cím elnevezése (pl. Otthon)',
    zip: 'Irányítószám',
    city: 'Város',
    street: 'Utca',
    number: 'Házszám',
    bell: 'Csengő',
    company: 'Cégnév',
    floor: 'Emelet / ajtó',
    selectCity: 'Város kiválasztás',
    saveAddress: 'Cím mentése',
    cancel: 'Mégse',
    deleteAddress: 'Cím törlése',
    noAddresses: 'Nincs mentett címed.',
    saveSuccessful: 'Sikeres mentés!',
    saveFailed: 'Sikertelen mentés!',
  },
  cart: {
    delivery: 'Házhozszállítás',
    takeway: 'Elvitel',
    local: 'Helyben fogyasztás',
    orderType: 'Rendelés típusa',
    deliveryAddress: 'Cím kiválasztása',
    billingAddress: 'Cím kiválasztása',
    paymentMethod: 'Fizetési mód',
    message: 'Megjegyzés',
    cupon: 'Kuponkód',
    send: 'Elküldés',
    cancel: 'Mégse',
    closeOrder: 'Megrendelés',
    emptyOrder: 'Kosár kiürítése',
    tableNumber: 'Asztal szám',
    deliveryAddressLabel: 'Szállítási cím',
    billingAddressLabel: 'Számlázási cím',
    takewayTime: 'Elvitel ideje',
    enterCuponCode: 'Add meg a kuponkódot',
    enterMessage: 'Üzenet',
    minimalAmount: 'A minimális rendelési összeg:',
    removeFromCartConfirm: 'Biztosan eltávolítod a kosárból?',
    select: 'Kiválaszt',
    table: 'asztal',
    deliveryPrice: 'Szállítási költség',
    discount: 'Kedvezmény',
    cartIsEmpty: 'A kosár üres',
    summary: 'Összesen',
    timeNotAvailable: 'Nem elérhető',
  },
  history: {
    slide1: {
      howManyTimes: 'Hányszor?',
      occasionPerMonth: 'alkalom/hó',
      occasions: 'ALKALOM',
      used: '%year%-ben eddig %count%-szer használtad az OkosPincér alkalmazást, ami összesen',
    },
    slide2: {
      where: 'Hol?',
      mostFrequent: '%year%-ben legtöbb alkalommal és legtöbb fogyasztással a',
      yourFavoritePlace: 'a kedvenc helyed',
    },
    slide3: {
      money: 'FORINT',
      howMuch: 'Mennyit?',
      spending: '%year%-ben átlagosan %average% forintot költöttél havonta, alkalmanként pedig',
      average: '%average% forintot',
      info: `Minden felhasználónk aki eléri a <strong>250 ezer forintos</strong>
      határt meglepetés ajándékkal jutalmazzuk!`,
    },
    slide4: {
      favorite: 'KEDVENC',
      buddy: 'HAVER',
      place: 'HELY',
      how: 'Hogyan?',
      placesAndFriends: '%year%-ben %stores% helyen fordultál meg, %favorites%-t kedvencnek jelöltél, és',
      friendsUsingOP: 'barátod használja még az OkosPincért',
    },
    slide5: {
      orders: 'Rendelés',
      what: 'Mit?',
      amount: 'Összeg',
    },
  },
  intro: {
    login: 'Belépés',
    register: 'Regisztráció',
    browseWithoutLogin: 'Böngészés belépés nélkül',
    slide1Title: 'Rendelj okosan',
    slide1Content: 'Böngészd át a választékot képekkel és árakkal és rendelj a telefonoddal',
    slide2Title: 'Foglalj okosan',
    slide2Content: 'Asztalfoglalás előre gyorsan és könnyedén telefonhívás nélkül',
    slide3Title: 'Válassz okosan',
    // tslint:disable-next-line: max-line-length
    slide3Content: 'Nézd át a kiemelt ajánlatokat, kedvezményeket és akciókat mielőtt helyet választasz',
    slide4Title: 'Kedvencek',
    // tslint:disable-next-line: max-line-length
    slide4Content: 'Gyűjtsd össze a kedvenc helyeid, így gyorsan tudsz dönteni hova szeretnétek menni',
  },
  landing: {
    status1: 'A rendelést megkaptuk és készítjük...',
    status2: 'Elkészült a rendelés, már csomagoljuk...',
    status3: 'Átadtuk a rendelést a futárnak',
    status4: 'A futár a rendeléssel hamarosan odaér...',
    toProducts: 'A termékekhez',
    ordersInProgress: 'Folyamatban lévő rendelések',
    estimatedRemainingTime: 'Becsült hátralévő idő',
  },
  loading: {
    loadingModules: 'Alkalmazás betöltése...',
  },
  login: {
    loginDescription: `<br />
    <strong>Az alkalmazás hozzáfér</strong> a publikus adataidhoz:<br />
    - a barátok listájához,<br />
    - a profiladatokhoz,<br />
    - és a profilképhez.<br /><br />
    Az alkalmazás <strong>nem tesz semmit közzé</strong> a profilodon az engedélyed nélkül,
    továbbá <strong> az adataidat bizalmasan kezeljük és nem adjuk át</strong>
    harmadik félnek. Mindez a te kényelmedet szolgálja.`,
    loginWithFacebook: 'Belépés Facebook profillal',
    loginWithGoogle: 'Belépés Google profillal',
  },
  menu: {
    favorite: 'Kedvenc',
    buddy: 'Haver',
    occasion: 'Alkalom',
    search: 'Keresés',
    products: 'Termékek',
    orders: 'Rendelések',
    profile: 'Profilod',
    history: 'Előzmények',
    addresses: 'Címek',
    faq: 'GYIK',
  },
  product: {
    minuses: 'Letétek',
    toppings: 'Feltétek',
    addToCart: 'Kosárba',
    loginToOrder: 'A rendeléshez belépés szükséges!',
    fillMandatoryFields: 'A csillaggal (*) jelölt csoportok közül kötelező választani!',
    addedToCart: 'A termék hozzáadva a kosárhoz',
  },
  settings: {
    profileData: 'Profil adatok',
    name: 'Név',
    email: 'Email cím',
    phone: 'Telefonszám',
    language: 'Nyelv',
    hun: 'Magyar',
    eng: 'English',
    save: 'Adatok mentése',
    saveSuccess: 'Sikeres mentés!',
    saveFailed: 'Sikertelen mentés!',
    fbsecurity: `Az adataid nem adjuk át meghatalmazásod nélkül semmilyen harmadik félnek, és nem tesszük sehol nyilvánossá.
    Továbbá biztonságosan tároljuk és csak az alkalmazás kényelmes működéséhez használjuk.`,
    bannedText: 'Sajnáljuk de az alkalmazást nem tudod használni, mert letiltotta a(z) %shopname% vendéglátó egység.',
    contactWithShop: `Kérjük a feloldáshoz <strong>lépj kapcsolatba</strong> velük,
    és amint a probléma megoldódott, újra tudod használni az alkalmazást.`,
  },
  shop: {
    garden: 'udvarhelység',
    privateRoom: 'különterem',
    delivery: 'házhozszállítás',
    menu: 'menü',
    liveMusic: 'élőzene',
    sportsLive: 'sportközvetítés',
    monday: 'Hetfő',
    tuesday: 'Kedd',
    wednesday: 'Szerda',
    thursday: 'Csütörtök',
    friday: 'Péntek',
    saturday: 'Szombat',
    sunday: 'Vasárnap',
    closed: 'Zárva',
    selectShop: 'Termékek',
  },
  start: {
    noAdresses: 'Még nem vettél fel szállítási címet, kérlek adj meg egyet!',
    howItWorks: 'Hogy működik az alkalmazásunk? Miért érdemes használni?',
    suggestPlace: 'Ajánlj egy helyet, ahol szeretnéd, hogy ha az alkalmazásunk elérhető lenne.',
    selectCity: 'Keress egy helyet itt: <span>%city%</span>',
  },
  scroller: {
    select: 'Kiválaszt',
    cancel: 'Mégsem',
  },
  search: {
    currentlyClosed: 'Jelenleg zárva vannak',
    currentlyOpen: 'Jelenleg nyitva vannak',
    deliveryFrom: 'Házhozszállítás %price% forinttól',
    closedToday: 'Ma zárva van',
    openAt: 'Nyitás ekkor: %open%',
  },
};
