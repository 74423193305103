import * as React from 'react';
import style from './Addresses.module.less';
import { IAddressesProps, IAddressesState, IStreet, ICity } from './Addresses.interface';
import { mapStateToProps, mapDispatchToProps } from './Addresses.connect';
import { connect } from 'react-redux';
import { Image } from '../Image/Image';
import op_shipping from '../../assets/images/op_shipping.jpg';
import refresh from '../../assets/images/refresh@2x.png';
import shipping from '../../assets/images/shipping@2x.png';
import { IAddress } from '../Menu/Menu.interface';
import { getFormattedAddress } from '../Cart/Cart';
import { get } from 'lodash';
import Scroller from '../Scroller/Scroller';
import getModuleLanguage from '../Dictionaries/Dictionaries';
import Toast from '../Toast/Toast';
let dictionary: any;
const inputFields = (moduleDictionary: any): any => [
  {
    id: 'title',
    label: moduleDictionary.title,
    hideIfEmptyCity: false,
    hideIfEmptyZip: false,
    mandatory: true,
  },
  {
    id: 'zip',
    label: moduleDictionary.zip,
    type: 'number',
    hideIfEmptyCity: false,
    hideIfEmptyZip: false,
    mandatory: true,
  },
  {
    id: 'city',
    label: moduleDictionary.city,
    hideIfEmptyCity: false,
    mandatory: true,
  },
  {
    id: 'street',
    label: moduleDictionary.street,
    mandatory: true,
    type: 'select',
  },
  {
    id: 'number',
    label: moduleDictionary.number,
    mandatory: true,
  },
  {
    id: 'floor',
    label: moduleDictionary.floor,
  },
  {
    id: 'bell',
    label: moduleDictionary.bell,
  },
  {
    id: 'company',
    label: moduleDictionary.company,
  },
];

class Addresses extends React.Component<IAddressesProps, IAddressesState> {
  public zipinterval: number | undefined = undefined;
  public constructor(props: IAddressesProps) {
    super(props);
    dictionary = getModuleLanguage('addresses');
    this.state = {
      showform: null,
      showScroller: false,
    };
  }

  public render() {
    const { isLoading } = this.props;
    const { showform, showScroller } = this.state;
    const scrollerProps = {
      hideScroller: this.hideScroller,
      renderScrollerElements: this.renderScrollerElements,
      title: dictionary.selectCity,
    };
    return (
      <section className={`${style.addresses}`}>
        {isLoading &&
          <div className="loading loadingAnimation">
            <img src={refresh} />
          </div>
        }
        <Image src={op_shipping} />
        <div className={style.stickylist}>
          <ul className={style.addressList}>
            {this.renderAddresses()}
          </ul>
          {!showform &&
            <a
              className={`${style.button} ${style.newaddress} ripple centered pulseAnimation`}
              onClick={() => this.getAddress('')}
            >+</a>
          }
        </div>
        {showform &&
          <div className={`${style.editAddress}`}>
            <form>
              <div className="row">
                <input type="hidden" className="id" />
                <input type="hidden" className="user_id" required={true} />
                { inputFields(dictionary).map(this.renderFormElements) }
                <div className={style.buttons}>
                  <a className="button ripple centered" onClick={this.saveAddress}>
                    {dictionary.saveAddress}
                  </a>
                  <a
                    className="button ripple centered"
                    onClick={() => this.setState({ showform: null })}
                  >
                    {dictionary.cancel}
                  </a>
                </div>
                <div>
                  {showform.id !== '0' &&
                    <a className="button ripple centered removeaddress transp">
                      {dictionary.deleteAddress}
                    </a>
                  }
                </div>
              </div>
            </form>
          </div>
        }
        {showScroller &&
          <Scroller {...scrollerProps} />
        }
      </section>
    );
  }

  private hideScroller = () => {
    const { showform } = this.state;
    if (showform) {
      this.props.action.getStreets(showform.zip);
    }
    this.setState({ showScroller: false });
  }

  private renderScrollerElements = () =>
    this.props.cities.map((item: ICity, index: number) => (
        <li
          className={`${item.zip === (this.state.showform && this.state.showform.zip)
            && 'active'} oneliner`}
          key={`scroll-${index}`}
          onClick={() => this.setCity(item)}
        >
          <Image src={shipping} className="scroller_icon" />
          <span className={style.item}>{item.name}</span>
          <span>{item.zip}</span>
        </li>
      ))

  private setCity = (item: ICity) =>
      (this.state.showform && this.setState({
        showform: {
          ...this.state.showform,
          city: item.name,
          zip: item.zip,
        },
      })) || undefined

  private renderFormElements = (field: any) => {
    const { showform } = this.state;
    const {
      hideIfEmptyCity = true,
      hideIfEmptyZip = true,
      type = 'input',
    } = field;
    if (!showform) {
      return null;
    }
    const {
      city = '',
      zip = '',
    } = showform;
    const hideByCity = hideIfEmptyCity && !city;
    const hideByZip = hideIfEmptyZip && !zip;
    const display = !hideByCity && !hideByZip;
    const commonProps = {
      className: style[field.id],
      required: true,
      value: showform[field.id],
      onChange: this.updateState(field.id),
    };
    const input = (<input type="text" {...commonProps} />);
    const select = (
      <select {...commonProps}>
        {this.renderStreets()}
      </select>
    );
    const number = (
      <input
        type="number"
        min="1000"
        max="9999"
        {...commonProps}
        onChange={this.filterZip}
      />
    );
    const fields: any = {
      input,
      select,
      number,
    };
    if (!display) {
      return null;
    }
    return (
      <div className="p15" key={`field-${field.id}`}>
        {fields[type]}
        <label htmlFor={field.id}>{field.label}{field.mandatory && '*'}</label>
      </div>
    );
  }

  private updateState = (field: string) =>
    (event: any) =>
      this.state.showform && this.setState({
        showform: {
          ...this.state.showform,
          [field]: event.target.value,
        },
      })

  private renderAddresses = () =>
    this.props.addresses.length ?
    this.props.addresses.map((item: IAddress) => (
      <li
        className={style.address}
        onClick={() => this.getAddress(item.id)}
        key={`address-${item.id}`}
      >
        <Image src={shipping} className={style.circle} />
        <div className={style.desc}>
          <b>{item.title}</b><br />
          {getFormattedAddress(item)}
        </div>
      </li>
    ))
    : (
      <li
        className={style.address}
      >
        <div className={style.desc}>
          <b>{dictionary.noAddresses}</b>
        </div>
      </li>
    )

  private getAddress = async (id: string) => {
    let address = {
      id: '',
      user_id: this.props.userId,
      title: '',
      zip: '',
      city: '',
      street: '',
      number: '',
      floor: '',
      bell: '',
      company: '',
    };
    if (id !== '') {
      address = this.props.addresses.filter((item: IAddress) => item.id === id)[0];
      await this.props.action.getStreets(address.zip);
    }
    this.setState({ showform: address });
  }

  private saveAddress = () => {
    if (this.state.showform) {
      this.props.action.saveAddress(this.state.showform)
      .then((response: any) => {
        const success = get(response, 'payload.data.success', false);
        Toast(success ? dictionary.saveSuccessful : dictionary.saveFailed);
        this.setState({ showform: success ? null : this.state.showform });
      });
    }
  }

  private renderStreets = () =>
    this.props.streets.map((street: IStreet) => (
      <option value={street.name} key={`street-${street.id}`}>{street.name}</option>
    ))

  private filterZip = (event: any) => {
    const zip = event.target.value;
    this.updateState('zip')(event);
    clearTimeout(this.zipinterval);
    this.zipinterval = window.setTimeout(
      async () => {
        await this.props.action.getCities(zip);
        this.setState({ showScroller: true });
      },
      1500,
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Addresses);
