import { clone, get } from 'lodash';
import { ADDRESSES_ACTIONS } from './Addresses.action';

const defaultAppState: any = {
  isLoading: false,
  cities: [],
  streets: [],
};

// tslint:disable-next-line: variable-name
export const Addresses = (
  state = clone(defaultAppState),
  action: any,
) => {
  switch (action.type) {
    case ADDRESSES_ACTIONS.GET_STREETS:
      return {
        ...state,
        isLoading: true,
      };
    case ADDRESSES_ACTIONS.FAIL.GET_STREETS:
      return {
        ...state,
        isLoading: false,
      };
    case ADDRESSES_ACTIONS.SUCCESS.GET_STREETS:
      return {
        ...state,
        isLoading: false,
        streets: get(action, 'payload.data.data', []),
      };
    case ADDRESSES_ACTIONS.GET_CITIES:
      return {
        ...state,
        isLoading: true,
      };
    case ADDRESSES_ACTIONS.FAIL.GET_CITIES:
      return {
        ...state,
        isLoading: false,
      };
    case ADDRESSES_ACTIONS.SUCCESS.GET_CITIES:
      return {
        ...state,
        isLoading: false,
        cities: get(action, 'payload.data.data', []),
      };
    case ADDRESSES_ACTIONS.UPDATE_USER_SETTINGS:
      return {
        ...state,
        isLoading: true,
      };
    case ADDRESSES_ACTIONS.FAIL.UPDATE_USER_SETTINGS:
      return {
        ...defaultAppState,
        isLoading: false,
      };
    case ADDRESSES_ACTIONS.SUCCESS.UPDATE_USER_SETTINGS:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
