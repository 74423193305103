import React from 'react';
import { withRouter } from 'react-router-dom';
import back from '../../assets/images/back@2x.png';
import { Image } from '../Image/Image';

// tslint:disable-next-line: variable-name
const GoBack = ({ history }: any) =>
  <Image src={back} className="icon" onClick={click(history)} alt="Go back" />;

// tslint:disable-next-line: interface-name
interface CordovaNavigator extends Navigator {
  app: {
    exitApp: () => any;
  };
}

const click = (history: any) =>
  () => {
    if (history.location.pathname === '/start') {
      if (Object.prototype.hasOwnProperty.call(window, 'cordova') && confirm('Biztosan ki akarsz lépni?')) {
        (navigator as CordovaNavigator).app.exitApp();
      }
    } else {
      history.goBack();
    }
  };

export default withRouter(GoBack);
