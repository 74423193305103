import * as React from 'react';
import { connect } from 'react-redux';

import { IAppProps, IAppState } from './App.interface';
import Footer from '../Footer/Footer';
import { mapDispatchToProps, mapStateToProps } from './App.connect';
import { Redirect } from 'react-router-dom';
import Header from '../Header/Header';
import Menu from '../Menu/Menu';
import { isEqual } from 'lodash';
const displayHeaderSreens = [
  'start',
  'search',
  'shop',
  'category',
  'products',
  'product',
  'cart',
  'landing',
  'settings',
  'addresses',
  'history',
  'faq',
  'friends',
  'map',
  'favorites',
];

export class App extends React.Component<IAppProps, IAppState> {
  constructor(props: IAppProps) {
    super(props);
    this.state = {
      redirect: '',
    };
  }

  public componentDidMount() {
    this.props.action.getFaq();
    this.props.action.getInitData().then(() => {
      this.setState({ redirect: '/intro' });
    });
  }

  public componentDidUpdate(props: IAppProps) {
    if (isEqual(props, this.props)) {
      return false;
    }
    if (parseInt(this.props.banned, 10) && !this.state.redirect) {
      this.setState({ redirect: '/settings' });
    }
  }

  public render() {
    const { isLoading, module, displayMenu, banned } = this.props;
    const pathname = window.location.pathname.split('/')[1];
    const showHeader = displayHeaderSreens.indexOf(pathname) >= 0 && !parseInt(banned, 10);
    if (!isLoading && this.state.redirect) {
      const { redirect } = this.state;
      this.setState({ redirect: '' });
      return (<Redirect to={{ pathname: redirect }} />);
    }
    return (
      <div>
          <>
            { displayMenu && <Menu /> }
            { showHeader && <Header /> }
            {module}
            <Footer />
          </>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
