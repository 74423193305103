import slide1 from '../../assets/images/illustr_1.png';
import slide2 from '../../assets/images/illustr_2.png';
import slide3 from '../../assets/images/illustr_3.png';
import slide4 from '../../assets/images/illustr_4.png';
import { ISlideProps } from './Slide/Slide.interface';

export const slidesConfig = (dictionary: any): ISlideProps[] => [
  {
    slide: slide1,
    header: dictionary.slide1Title,
    content: dictionary.slide1Content,
  },
  {
    slide: slide2,
    header: dictionary.slide2Title,
    content: dictionary.slide2Content,
  },
  {
    slide: slide3,
    header: dictionary.slide3Title,
    content: dictionary.slide3Content,
  },
  {
    slide: slide4,
    header: dictionary.slide4Title,
    content: dictionary.slide4Content,
  },
];
