import { clone, get } from 'lodash';
import { LOGIN_ACTIONS } from './Login.action';
import { ADDRESSES_ACTIONS } from '../Addresses/Addresses.action';
import { HISTORY_ACTIONS } from '../History/History.action';

const defaultAppState = {
  isLoading: false,
  userData: {},
  friends: [],
  history: {},
};

// tslint:disable-next-line: variable-name
export const Login = (
  state = clone(defaultAppState),
  action: any,
) => {
  switch (action.type) {
    case LOGIN_ACTIONS.SET_LOADING:
      return {
        ...state,
        isLoading: action.value,
      };
    case LOGIN_ACTIONS.SET_FRIENDS:
      return {
        ...state,
        friends: action.friends,
      };
    case LOGIN_ACTIONS.FACEBOOK_LOGIN:
      return {
        ...state,
        isLoading: true,
      };
    case LOGIN_ACTIONS.FAIL.FACEBOOK_LOGIN:
      return {
        ...defaultAppState,
        isLoading: false,
      };
    case LOGIN_ACTIONS.SUCCESS.FACEBOOK_LOGIN:
      const language = get(action, 'payload.data.data.language', 'en');
      localStorage.setItem('language', language);
      return {
        ...state,
        isLoading: false,
        userData: action.payload.data.data,
      };
    case ADDRESSES_ACTIONS.SUCCESS.UPDATE_USER_SETTINGS:
      return {
        ...state,
        userData: {
          ...state.userData,
          addresses: get(action, 'payload.data.addresses', []),
        },
      };
    case HISTORY_ACTIONS.SUCCESS.GET_HISTORY:
      return {
        ...state,
        history: get(action, 'payload.data.data', []),
      };
    case LOGIN_ACTIONS.SUCCESS.TOGGLE_FAVORITE:
      return {
        ...state,
        userData: {
          ...state.userData,
          favorites: get(action, 'payload.data.favorites', []),
        },
      };
    default:
      return state;
  }
};
