import { combineReducers } from 'redux';

import { App } from '../components/App/App.reducer';
import { Addresses } from '../components/Addresses/Addresses.reducer';
import { Cart } from '../components/Cart/Cart.reducer';
import { Category } from '../components/Category/Category.reducer';
import { Faq } from '../components/FAQ/FAQ.reducer';
import { History } from '../components/History/History.reducer';
import { Landing } from '../components/Landing/Landing.reducer';
import { Login } from '../components/Login/Login.reducer';
import { Menu } from '../components/Menu/Menu.reducer';
import { Product } from '../components/Product/Product.reducer';
import { Products } from '../components/Products/Products.reducer';
import { Search } from '../components/Search/Search.reducer';
import { Settings } from '../components/Settings/Settings.reducer';

const rootReducer = combineReducers({
  Addresses,
  App,
  Cart,
  Category,
  Faq,
  History,
  Landing,
  Login,
  Menu,
  Product,
  Products,
  Search,
  Settings,
});

export default rootReducer;
