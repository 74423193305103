import url_config from '../../util/url_config';
import { IFriend } from '../Menu/Menu.interface';

export const LOGIN_ACTIONS = {
  SET_LOADING: '[LOGIN] SET_LOADING',
  SET_FRIENDS: '[LOGIN] SET_FRIENDS',
  FACEBOOK_LOGIN: '[LOGIN] FACEBOOK_LOGIN',
  TOGGLE_FAVORITE: '[LOGIN] TOGGLE_FAVORITE',
  SUCCESS: {
    FACEBOOK_LOGIN: '[LOGIN] FACEBOOK_LOGIN_SUCCESS',
    TOGGLE_FAVORITE: '[LOGIN] TOGGLE_FAVORITE_SUCCESS',
  },
  FAIL: {
    FACEBOOK_LOGIN: '[LOGIN] FACEBOOK_LOGIN_FAIL',
    TOGGLE_FAVORITE: '[LOGIN] TOGGLE_FAVORITE_FAIL',
  },
};

export const setLoading = (value: boolean) => ({
  value,
  type: LOGIN_ACTIONS.SET_LOADING,
});

export const setFriends = (friends: IFriend[]) => ({
  friends,
  type: LOGIN_ACTIONS.SET_FRIENDS,
});

export const fbLogin = (data: any) => {
  return {
    payload: {
      client: url_config.DEFAULT_CLIENT,
      request: {
        method: 'GET',
        url: url_config.GLOBAL.GET_INIT_DATA,
        params: {
          action: 'op_get_state',
          ...data,
        },
      },
    },
    type: LOGIN_ACTIONS.FACEBOOK_LOGIN,
  };
};

export const toggleFavorite = (user: string, blogId: number) => {
  return {
    payload: {
      client: url_config.DEFAULT_CLIENT,
      request: {
        method: 'GET',
        url: url_config.GLOBAL.GET_INIT_DATA,
        params: {
          user,
          blog_id: blogId,
          action: 'op_add_to_favourites',
        },
      },
    },
    type: LOGIN_ACTIONS.TOGGLE_FAVORITE,
  };
};
