import { clone } from 'lodash';
import { FAQ_ACTIONS } from './FAQ.action';
import { IFaq } from './FAQ.interface';

const defaultMenuState = {
  faq: [],
};

// tslint:disable-next-line: variable-name
export const Faq = (
  state = clone(defaultMenuState),
  action: any,
) => {
  switch (action.type) {
    case FAQ_ACTIONS.GET_FAQ:
    case FAQ_ACTIONS.FAIL.GET_FAQ:
      return state;
    case FAQ_ACTIONS.SUCCESS.GET_FAQ:
      return {
        ...state,
        faq: action.payload.data.data
        .sort((a: IFaq, b: IFaq) =>
          a.parent.localeCompare(b.parent) && b.parent.localeCompare(a.id) ? 1 : -1),
      };
    default:
      return state;
  }
};
