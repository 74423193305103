import * as React from 'react';
import { mapStateToProps, mapDispatchToProps } from './FAQ.connect';
import { connect } from 'react-redux';
import style from './FAQ.module.less';
import { IFaqProps, IFaq, IFaqState } from './FAQ.interface';

class FAQ extends React.Component<IFaqProps, IFaqState> {
  public constructor(props: IFaqProps) {
    super(props);
    this.state = {
      opened: [],
    };
  }
  public render() {
    const { faq } = this.props;
    return (
      <section className={style.faq}>
        <div className={style.faq_holder}>
          {
            faq
            .map((item: IFaq) => (
              <div
                key={`faq-${item.id}`}
                onClick={item.parent !== '0' ? this.toggleCategory(item.id) : () => null}
                className={`
                  ${style.faq_item}
                  ${this.state.opened.indexOf(item.id) < 0 ? style.collapsed : ''}
                  ${item.parent === '0' ? ` ${style.parent}` : ''}
                `}
              >
                <div
                  className={`${style.title} trans05`}
                  dangerouslySetInnerHTML={{ __html: item.title }}
                />
                <div
                  className={`${style.content} trans05`}
                  dangerouslySetInnerHTML={{ __html: item.content }}
                />
              </div>
            ))
          }
        </div>
      </section>
    );
  }

  private toggleCategory = (id: string) =>
    () => {
      const { opened } = this.state;
      if (opened.indexOf(id) >= 0) {
        opened.splice(opened.indexOf(id), 1);
      } else {
        opened.push(id);
      }
      this.setState({ opened });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FAQ);
