import * as React from 'react';
import { mapStateToProps, mapDispatchToProps } from './Favorites.connect';
import { connect } from 'react-redux';
import { IFavoritesProps } from './Favorites.interface';
import { Image } from '../Image/Image';
import { IFavorite } from '../Menu/Menu.interface';
import { IShop } from '../Search/Search.interface';
import { NavLink } from 'react-router-dom';

class Favorites extends React.Component<IFavoritesProps> {
  public render() {
    const { favorites } = this.props;
    if (!Object.keys(favorites).length) {
      return null;
    }
    return (
      <section >
        <div className="stickylist">
          <ul>
              {favorites.map(this.mapFavorites)}
          </ul>
        </div>
      </section>
    );
  }

  private mapFavorites = (item: IFavorite) => {
    const shop = this.props.shops.filter((sitem: IShop) => sitem.blogid === item.blog_id)[0];
    return (
      <React.Fragment key={`favorite-${item.blog_id}`}>
        <li>
          <NavLink to={`shop/${shop.blogid}`}>
            <Image
              className="circle"
              src={shop.image}
              />
            <div className="desc">
              <b>{shop.blogname}</b><br />
            </div>
          </NavLink>
        </li>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Favorites);
