import * as React from 'react';
import getModuleLanguage from '../Dictionaries/Dictionaries';

export class NotFound extends React.Component {
  public render() {
    const dictionary = getModuleLanguage('notFound');
    return (
      <section className="error">
        <h1>{dictionary.notFoundMessage}</h1>
      </section>
    );
  }
}
