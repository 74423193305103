import React from 'react';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { multiClientMiddleware } from 'redux-axios-middleware';
import { composeWithDevTools } from 'redux-devtools-extension';
import reduxThunk from 'redux-thunk';

import { clients } from './middlewares/axiosMiddleware/clients';
import config from './middlewares/axiosMiddleware/config';
import reducers from './reducers';

interface IRootChildren {
  children: JSX.Element;
}

const middlewares = [reduxThunk, multiClientMiddleware(clients, config)];
const store = createStore(reducers, composeWithDevTools(applyMiddleware(...middlewares)));

export default ({ children }: IRootChildren) => {
  return (
    <Provider store={ store }>
      { children }
    </Provider>
  );
};
