import { dictionary as hun } from './Languages/hun';
import { dictionary as en } from './Languages/eng';

const getModuleLanguage = (module: string) => {
  const deviceLanguage = navigator.language;
  const language = localStorage.getItem('language') || deviceLanguage;
  let dictionary = en;
  if (language.indexOf('hu') >= 0) {
    dictionary = hun;
  }
  return dictionary[module];
};

export default getModuleLanguage;
