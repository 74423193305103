import { getProducts } from './Products.action';
import { IShop } from '../Search/Search.interface';

export const mapStateToProps = ({ Products, App, Search }: any) => ({
  products: Products.products,
  isLoading: Products.isLoading,
  shop: Search.shops.filter((shop: IShop) =>
    shop.blogid === App.selectedShop)[0],
});

export const mapDispatchToProps = (dispatch: any) => ({
  action: {
    getProducts: (store: string, id: string) => dispatch(getProducts(store, id)),
  },
});
