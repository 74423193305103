import { getCurrentOrders } from './Landing.action';
import { IShop } from '../Search/Search.interface';

export const mapStateToProps = ({ Landing, Login, Search, App }: any) => ({
  action: Landing.action,
  isLoading: Landing.isLoading,
  orders: Landing.orders,
  userId: Login.userData.id,
  shop: Search.shops.filter((shop: IShop) =>
    shop.blogid === App.selectedShop)[0],
});

export const mapDispatchToProps = (dispatch: any) => ({
  action: {
    getCurrentOrders: (blogId: string, userId: string) =>
      dispatch(getCurrentOrders(blogId, userId)),
  },
});
