import * as React from 'react';
import style from './Scroller.module.less';
import getModuleLanguage from '../Dictionaries/Dictionaries';
let dictionary: any;

export interface IScollerProps {
  title: string;
  selectLabel?: string;
  cancelLabel?: string;
  isTextarea?: boolean;
  hideScroller: () => void;
  renderScrollerElements: () => null | JSX.Element[] | JSX.Element;
}
class Scroller extends React.Component<IScollerProps> {
  constructor(props: IScollerProps) {
    super(props);
    dictionary = getModuleLanguage('scroller');
  }

  public render() {
    const {
      title,
      hideScroller,
      isTextarea = false,
      renderScrollerElements,
      selectLabel = dictionary.select,
      cancelLabel = dictionary.cancel,
    } = this.props;
    return (
      <div className={`container ${style.scroller_container}`}>
        <div className={style.scroller_holder}>
          <h3 className={style.title}>{title}</h3>
          {!isTextarea &&
            <div className={style.scroller}>
              <div className={style.scroll_container}>
                <ul className={style.scroll}>
                  {renderScrollerElements()}
                </ul>
              </div>
            </div>
          }
          {isTextarea &&
            <div className={style.scroller}>
              <div className={style.scroll_container}>
                {renderScrollerElements()}
              </div>
            </div>
          }
          <div className={style.button_holder}>
            <a
              className={`button forcebg ${style.button}`}
              onClick={hideScroller}
            >
              {selectLabel}
            </a>
            <a
              className={`button transp ${style.button}`}
              onClick={hideScroller}
            >
              {cancelLabel}
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Scroller;
