import { saveProfile } from './Settings.action';

export const mapStateToProps = ({ Login, Search }: any) => ({
  user: Login.userData,
  shops: Search.shops,
});

export const mapDispatchToProps = (dispatch: any) => ({
  action: {
    saveProfile: (data: any) => dispatch(saveProfile(data)),
  },
});
