interface IApiUrl {
  DEFAULT_CLIENT: string;
  GLOBAL: {
    GET_INIT_DATA: string,
  };
}

const defaultConfig: IApiUrl = {
  DEFAULT_CLIENT: 'dev',
  GLOBAL: {
    GET_INIT_DATA: '/wp-admin/admin-ajax.php',
  },
};

export const testUrl: IApiUrl = {
  DEFAULT_CLIENT: 'test',
  ...defaultConfig,
};

export const devUrl: IApiUrl = {
  ...defaultConfig,
};

export const prodUrl: IApiUrl = {
  DEFAULT_CLIENT: 'production',
  ...defaultConfig,
};

let envUrl: IApiUrl;

switch (process.env.NODE_ENV) {
  case 'development':
    envUrl = devUrl;
    break;
  case 'production':
    envUrl = prodUrl;
    break;
  default:
    envUrl = devUrl;
}

export default envUrl;
