import * as React from 'react';
import getModuleLanguage from '../Dictionaries/Dictionaries';
let dictionary: any;
import style from './Shop.module.less';
import globe from '../../assets/images/globe@2x.png';
import clock from '../../assets/images/clock@2x.png';
import money from '../../assets/images/money@2x.png';
import browser from '../../assets/images/browser@2x.png';
import tree from '../../assets/images/tree@2x.png';
import user from '../../assets/images/user@2x.png';
import box from '../../assets/images/box@2x.png';
import doc from '../../assets/images/doc@2x.png';
import music from '../../assets/images/music@2x.png';
import tv from '../../assets/images/tv@2x.png';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from './Shop.connect';
import { IShopProps } from './Shop.interface';
import { IShop } from '../Search/Search.interface';
import { NavLink } from 'react-router-dom';

const attributesConfig = (moduleDictionary: any) => [
  {
    name: 'garden',
    key: 'blog_garden',
    image: tree,
    label: moduleDictionary.garden,
  },
  {
    name: 'room',
    key: 'blog_room',
    image: user,
    label: moduleDictionary.privateRoom,
  },
  {
    name: 'shipping',
    key: 'blog_shipping',
    image: box,
    label: moduleDictionary.delivery,
  },
  {
    name: 'menu',
    key: 'blog_menu',
    image: doc,
    label: moduleDictionary.menu,
  },
  {
    name: 'music',
    key: 'blog_music',
    image: music,
    label: moduleDictionary.liveMusic,
  },
  {
    name: 'sport',
    key: 'blog_sport',
    image: tv,
    label: moduleDictionary.sportsLive,
  },
];

class Shop extends React.Component<IShopProps> {
  private id = window.location.pathname.split('/')[2];
  private selectedShop: IShop;
  constructor(props: IShopProps) {
    super(props);
    this.selectedShop =
      (this.props.shops || []).filter((item: IShop) => item.blogid === this.id)[0];
    dictionary = getModuleLanguage('shop');
  }

  public render() {
    // tslint:disable-next-line: no-this-assignment
    const { selectedShop } = this;
    console.log(window.location.pathname.split('/'), this.id);
    if (selectedShop === undefined || !Object.keys(selectedShop).length) {
      return null;
    }
    return (
      <section className={style.shop}>
        <figure className="bigimage" style={{ backgroundImage: `url(${selectedShop.image})` }} />
        <div className="shopdesc">
          <p>{selectedShop.blog_description}</p>
        </div>
        <div className="shoptel">
          <a className="forcecolor">{selectedShop.blog_phone}</a>
        </div>
        <ul>
          <li>
            <img src={globe} />
            <span className="address">{selectedShop.address}</span>
          </li>
          <li>
            <img src={clock} />
            <span
              className="open"
              dangerouslySetInnerHTML={{ __html: this.mapOpeningHours(selectedShop.blog_open) }}
            />
          </li>
          <li>
            <img src={money} />
            <span className="payment">{selectedShop.blog_phone}</span>
          </li>
          <li>
            <img src={browser} />
            <span className="website">{selectedShop.siteurl}</span>
          </li>
        </ul>
        <div className={style.parameters}>
          {attributesConfig(dictionary).map((item: any) =>
            <img
              src={item.image}
              className={`${item.name} ${selectedShop[item.key] !== 'yes' && style.dis}`}
              key={`icon-${item.name}`}
            />)}
          <small>
            {attributesConfig(dictionary).map((item: any, index: number) =>
              <span
                className={`${item.name} ${selectedShop[item.key] !== 'yes' && style.dis}`}
                key={`text-${item.name}`}
              >
                {`${index !== 0 ? ' / ' : ''}${item.label}`}
              </span>)}
          </small>
        </div>
        <div>
          <NavLink
            className="button"
            to={{ pathname: `/category/${selectedShop.blogid}` }}
            onClick={() => this.props.action.selectShop(selectedShop.blogid)}
          >
            {dictionary.selectShop}
          </NavLink>
        </div>
      </section>
    );
  }

  private mapOpeningHours = (blogOpen: string[]) => {
    let newVal = '';
    const days = [
      dictionary.monday,
      dictionary.tuesday,
      dictionary.wednesday,
      dictionary.thursday,
      dictionary.friday,
      dictionary.saturday,
      dictionary.sunday,
    ];
    if (blogOpen) {
      blogOpen.forEach((item, key) => {
        if (!(key % 2)) {
          newVal += `${days[Math.floor(key / 2)]}: `;
          const isClosed = item === '00:00' && blogOpen[key + 1] === '00:00';
          newVal += (isClosed ? `${dictionary.closed}<br />` : `${item} - ${blogOpen[key + 1]}<br />`);
        }
      });
    }
    return newVal;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Shop);
