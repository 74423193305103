import {
  checkIn,
  getCategories,
  getToppings,
  getMinuses,
  getSlides,
  getShopData,
} from './Category.action';
import { IShop } from '../Search/Search.interface';
export const mapStateToProps = ({ Category, Search, App }: any) => ({
  isLoading: Category.isLoading,
  categories: Category.categories,
  slides: Category.slides,
  shop: Search.shops.filter((shop: IShop) =>
    shop.blogid === App.selectedShop)[0],
});

export const mapDispatchToProps = (dispatch: any) => ({
  action: {
    checkIn: (id: string) => dispatch(checkIn(id)),
    getCategories: () => dispatch(getCategories()),
    getToppings: () => dispatch(getToppings()),
    getMinuses: () => dispatch(getMinuses()),
    getSlides: () => dispatch(getSlides()),
    getShopData: (blogid: string) => dispatch(getShopData(blogid)),
  },
});
