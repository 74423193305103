import * as React from 'react';
import style from './Loading.module.less';
import img from '../../assets/images/logo@2x.png';
import { version as localVersion } from '../../../package.json';
import getModuleLanguage from '../Dictionaries/Dictionaries';
let dictionary: any;
import { ILoadingProps } from './Loading.interface';
import { mapStateToProps } from './Loading.connect';
import { connect } from 'react-redux';
import Toast from '../Toast/Toast';

class Loading extends React.Component<ILoadingProps> {
  public render() {
    dictionary = getModuleLanguage('loading');
    const {
      version = localVersion,
    } = this.props;
    if (version !== localVersion) {
      Toast('Verziók nem egyeznek!');
    }
    return (
      <section className={`${style.loading} noheader active`}>
        <div>
          <img src={img} />
          <span className={`${style.version}`}>{localVersion}</span>
          <span className={`${style.action}`}>{dictionary.loadingModules}</span>
        </div>
    </section>
    );
  }
}

export default connect(mapStateToProps, {})(Loading);
