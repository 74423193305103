import { clone, get } from 'lodash';
import { CATEGORY_ACTIONS } from './Category.action';

const defaultAppState: any = {
  isLoading: true,
  categories: [],
  minuses: [],
  toppings: [],
  toppingCategories: [],
  slides: [],
};

// tslint:disable-next-line: variable-name
export const Category = (
  state = clone(defaultAppState),
  action: any,
) => {
  switch (action.type) {
    case CATEGORY_ACTIONS.GET_CATEGORIES:
    case CATEGORY_ACTIONS.FAIL.GET_CATEGORIES:
      return state;
    case CATEGORY_ACTIONS.SUCCESS.GET_CATEGORIES:
      return {
        ...state,
        categories: action.payload.data.data,
      };
    case CATEGORY_ACTIONS.GET_TOPPINGS:
    case CATEGORY_ACTIONS.FAIL.GET_TOPPINGS:
      return state;
    case CATEGORY_ACTIONS.SUCCESS.GET_TOPPINGS:
      return {
        ...state,
        toppings: action.payload.data.data,
        toppingCategories: Object.keys(action.payload.data.categories)
          .map((value: string) => ({
            ...action.payload.data.categories[value],
            id: value,
          })),
      };
    case CATEGORY_ACTIONS.GET_SHOP_DATA:
    case CATEGORY_ACTIONS.FAIL.GET_SHOP_DATA:
      return state;
    case CATEGORY_ACTIONS.SUCCESS.GET_SHOP_DATA:
      const toppingCategories = get(action, 'payload.data.data.topping_categories', []);
      const minuses = get(action, 'payload.data.data.minus', {});
      return {
        ...state,
        minuses: Object.keys(minuses).map(e => minuses[e]),
        categories: get(action, 'payload.data.data.category', []),
        toppings: get(action, 'payload.data.data.topping', []),
        slides: get(action, 'payload.data.data.slides', []),
        toppingCategories: Object.keys(toppingCategories)
          .map((value: string) => toppingCategories[value]),
        isLoading: false,
      };
    case CATEGORY_ACTIONS.GET_MINUSES:
    case CATEGORY_ACTIONS.FAIL.GET_MINUSES:
      return state;
    case CATEGORY_ACTIONS.SUCCESS.GET_MINUSES:
      return {
        ...state,
        minuses: Object.keys(action.payload.data.data).map(e => action.payload.data.data[e]),
      };
    case CATEGORY_ACTIONS.GET_SLIDES:
    case CATEGORY_ACTIONS.FAIL.GET_SLIDES:
      return state;
    case CATEGORY_ACTIONS.SUCCESS.GET_SLIDES:
      return {
        ...state,
        slides: action.payload.data.data,
      };
    case CATEGORY_ACTIONS.CHECK_IN:
      return defaultAppState;
    case CATEGORY_ACTIONS.SUCCESS.CHECK_IN:
      return {
        ...state,
        isLoading: false,
      };
    case CATEGORY_ACTIONS.FAIL.CHECK_IN:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
