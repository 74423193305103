import { clone, get } from 'lodash';
import { PRODUCT_ACTIONS } from './Product.action';

const defaultAppState = {
  isLoading: true,
  product: [],
};

// tslint:disable-next-line: variable-name
export const Product = (
  state = clone(defaultAppState),
  action: any,
) => {
  switch (action.type) {
    case PRODUCT_ACTIONS.GET_PRODUCT:
      return defaultAppState;
    case PRODUCT_ACTIONS.SUCCESS.GET_PRODUCT:
      return {
        ...state,
        isLoading: false,
        product: get(action, 'payload.data.data', [{}])[0],
      };
    case PRODUCT_ACTIONS.FAIL.GET_PRODUCT:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
