import { getShops } from './Search.action';
import { selectShop } from '../App/App.action';

export const mapStateToProps = ({ Search }: any) => ({
  isLoading: Search.isLoading,
  shops: Search.shops,
});

export const mapDispatchToProps = (dispatch: any) => ({
  action: {
    getShops: () => dispatch(getShops()),
    selectShop: (id: string) => dispatch(selectShop(id)),
  },
});
