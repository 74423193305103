import * as React from 'react';
import { NavLink } from 'react-router-dom';
import style from './Login.module.less';
import getModuleLanguage from '../Dictionaries/Dictionaries';
let dictionary: any;
import img from '../../assets/images/login.jpg';
import fbimg from '../../assets/images/fb@2x.png';
import { mapStateToProps, mapDispatchToProps } from './Login.connect';
import { connect } from 'react-redux';
import { ILoginProps } from './Login.interface';

class Login extends React.Component<ILoginProps> {
  public render() {
    dictionary = getModuleLanguage('login');
    return (
      <section className={`${style.login} noheader`}>
        <img className={`${style.bigimage}`} src={img} />
        <div className={`${style.content}`}>
          <p dangerouslySetInnerHTML={{ __html: dictionary.loginDescription }} />
          <NavLink
            className={`${style.button} button ripple centered ins`}
            to={'start'}
            onClick={this.fbLogin}
          >
            <img src={fbimg} />
            <span>{dictionary.loginWithFacebook}</span>
          </NavLink>
          {/* <NavLink
            className={`${style.button} button ripple centered ins`}
            to={'start'}
            onClick={this.googleLogin}
          >
            <img src={fbimg} />
            <span>{dictionary.loginWithGoogle}</span>
          </NavLink> */}
        </div>
      </section>
    );
  }

  public fbLogin = () => {
    // e.preventDefault();
    this.props.action.setLoading(true);
    if (!window.hasOwnProperty('fbAsyncInit')) {
      Object.defineProperty(window, 'fbAsyncInit', {
        value: this.initFB,
        writable: false,
      });
      this.loadScript(
        document,
        'script',
        'facebook-jssdk',
        'https://connect.facebook.net/en_US/sdk.js',
      );
    } else {
      FB.getLoginStatus(this.checkFacebookLogin);
    }
  }

  public initFB = () => {
    FB.init({
      appId      : '783550655066903',
      cookie     : true,
      xfbml      : true,
      version    : 'v3.3',
    });
    FB.AppEvents.logPageView();
    FB.getLoginStatus(this.checkFacebookLogin);
  }

  public checkFacebookLogin = (response: any) => {
    if (response.status !== 'connected') {
      FB.login((loginData: any) => {
        if (loginData.authResponse) {
          this.getFields();
        } else {
          // console.log('User cancelled login or did not fully authorize.');
        }
      });
    } else {
      this.getFields();
    }
  }

  public getFields = () => {
    FB.api('/me?fields=name,email,friends', (response: any) => {
      this.props.action.setFriends(response.friends);
      this.props.action.fbLogin({
        fbid: response.id,
        fbfullname: response.name,
        femail: response.email,
      }).then(() => {
        this.props.action.getHistory(response.id);
        this.props.action.getShops();
      });
    });
  }

  public googleLogin = (e: any) => {
    e.preventDefault();

    /*(function(d, s, id){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {return;}
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://apis.google.com/js/platform.js';
      js.onload = () => {
        const auth2 = gapi.auth2.getAuthInstance();
        if (auth2.isSignedIn.get()) {
          var profile = auth2.currentUser.get().getBasicProfile();
          console.log('ID: ' + profile.getId());
          console.log('Full Name: ' + profile.getName());
          console.log('Given Name: ' + profile.getGivenName());
          console.log('Family Name: ' + profile.getFamilyName());
          console.log('Image URL: ' + profile.getImageUrl());
          console.log('Email: ' + profile.getEmail());
        }
      }
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'gmaillogin'));*/

  }

  public loadScript = (d: any, s: string, id: string, url: string) => {
    let js;
    const fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      return;
    }
    js = d.createElement(s); js.id = id;
    js.src = url;
    fjs.parentNode.insertBefore(js, fjs);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
