import axios from "axios";

export const clients = {
  dev: {
    client: axios.create({
      baseURL: "https://www.opadmin.tk/",
      responseType: "json",
      withCredentials: true
    })
  },
  production: {
    client: axios.create({
      baseURL: "https://www.okospincer.com/admin/",
      responseType: "json",
      withCredentials: true
    })
  }
};
