import * as React from 'react';
import ReactDOMServer from 'react-dom/server';
import style from '../History.module.less';
import getModuleLanguage from '../../Dictionaries/Dictionaries';
import { IHistoryProps } from '../History.interface';
let dictionary: any;

// tslint:disable-next-line: variable-name
const Slide1 = (props: IHistoryProps) => {
  const { history } = props;
  const date = new Date();
  const month = date.getMonth() + 1;
  dictionary = getModuleLanguage('history');
  const content = dictionary.slide1.used
  .replace(
    '%year%',
    ReactDOMServer.renderToString(<span className={style.year}>{date.getFullYear()}</span>),
  )
  .replace(
    '%count%',
    ReactDOMServer.renderToString(<span className={style.count}>{history.count}</span>),
  );
  const occasions = Math.round(history.count / month);
  return (
    <div className={`${style.slide} tcenter slide1`}>
      <h1>
        <span className={style.percent}>{occasions}</span>
        <strong>{dictionary.slide1.occasions}</strong>
      </h1>
      <h4>{dictionary.slide1.howManyTimes}</h4>
      <p dangerouslySetInnerHTML={{ __html: content }} />
      <h3>
        <span>{occasions}</span> {dictionary.slide1.occasionPerMonth}
      </h3>
      <div className={`${style.months}`}>

        {
          // tslint:disable-next-line: variable-name
          Array(12).fill(0).map((_item: number, index: number) => {
            const monthIndex = `0${index + 1}`.slice(-2);
            const item = history.months[monthIndex] || 0;
            const height = Math.round(item / history.sum * 100);
            return (
              <div
                className={`month${monthIndex}`}
                key={`month-${monthIndex}`}
              >
                <span style={{ height: `${height}%` }} />
              </div>
            );
          })
        }
      </div>
    </div>
  );
};

export default Slide1;
