import { toggleMenu } from './Menu.action';
import { IShop } from '../Search/Search.interface';

export const mapStateToProps = ({ Menu, Login, App, Search }: any) => ({
  displayMenu: Menu.displayMenu,
  userData: Login.userData,
  history: Login.history,
  friends: Login.friends,
  shop: Search.shops.filter((shop: IShop) =>
    shop.blogid === App.selectedShop)[0],
});

export const mapDispatchToProps = (dispatch: any) => ({
  action: {
    toggleMenu: () => dispatch(toggleMenu()),
  },
});
