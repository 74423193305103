import url_config from '../../util/url_config';
import { IAddress } from '../Menu/Menu.interface';

export const ADDRESSES_ACTIONS = {
  UPDATE_USER_SETTINGS: '[SETTINGS] UPDATE_USER_SETTINGS',
  GET_CITIES: '[SETTINGS] GET_CITIES',
  GET_STREETS: '[SETTINGS] GET_STREETS',
  SUCCESS: {
    UPDATE_USER_SETTINGS: '[SETTINGS] UPDATE_USER_SETTINGS_SUCCESS',
    GET_CITIES: '[SETTINGS] GET_CITIES_SUCCESS',
    GET_STREETS: '[SETTINGS] GET_STREETS_SUCCESS',
  },
  FAIL: {
    UPDATE_USER_SETTINGS: '[SETTINGS] UPDATE_USER_SETTINGS_FAIL',
    GET_CITIES: '[SETTINGS] GET_CITIES_FAIL',
    GET_STREETS: '[SETTINGS] GET_STREETS_FAIL',
  },
};

export const saveAddress = (address: IAddress) => {
  const data = new FormData();
  Object.keys(address).forEach((item) => {
    data.append(`address[${item}]`, address[item]);
  });
  data.append('action', 'op_save_address');
  return {
    payload: {
      client: url_config.DEFAULT_CLIENT,
      request: {
        data,
        method: 'POST',
        url: url_config.GLOBAL.GET_INIT_DATA,
      },
    },
    type: ADDRESSES_ACTIONS.UPDATE_USER_SETTINGS,
  };
};

export const getCities = (query: string) => {
  const data = new FormData();
  data.append('query', query);
  data.append('action', 'op_get_cities');

  return {
    payload: {
      client: url_config.DEFAULT_CLIENT,
      request: {
        data,
        method: 'POST',
        url: url_config.GLOBAL.GET_INIT_DATA,
      },
    },
    type: ADDRESSES_ACTIONS.GET_CITIES,
  };
};

export const getStreets = (zip: string) => {
  const data = new FormData();
  data.append('zip', zip);
  data.append('action', 'op_get_streets');

  return {
    payload: {
      client: url_config.DEFAULT_CLIENT,
      request: {
        data,
        method: 'POST',
        url: url_config.GLOBAL.GET_INIT_DATA,
      },
    },
    type: ADDRESSES_ACTIONS.GET_STREETS,
  };
};
