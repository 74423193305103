import { saveAddress, getStreets, getCities } from './Addresses.action';
import { IAddress } from '../Menu/Menu.interface';

export const mapStateToProps = ({ Login, Addresses }: any) => ({
  addresses: Login.userData.addresses || [],
  userId: Login.userData.id,
  isLoading: Addresses.isLoading,
  streets: Addresses.streets,
  cities: Addresses.cities,
});

export const mapDispatchToProps = (dispatch: any) => ({
  action: {
    saveAddress: (data: IAddress) => dispatch(saveAddress(data)),
    getStreets: (zip: string) => dispatch(getStreets(zip)),
    getCities: (query: string) => dispatch(getCities(query)),
  },
});
