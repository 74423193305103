import * as React from 'react';
import { mapStateToProps, mapDispatchToProps } from './Friends.connect';
import { connect } from 'react-redux';
import { IFriendsProps } from './Friends.interface';
import { Image } from '../Image/Image';
import { IFriend } from '../Menu/Menu.interface';

class Friends extends React.Component<IFriendsProps> {
  public render() {
    const { friends } = this.props;
    if (!Object.keys(friends).length) {
      return null;
    }
    return (
      <section >
        <div className="stickylist">
          <ul>
              {friends.map(this.mapFriends)}
          </ul>
        </div>
      </section >
    );
  }

  private mapFriends = (item: IFriend) => {
    return (
      <React.Fragment key={`friend-${item.id}`}>
        <li>
            <Image
              className="circle"
              src={`https://graph.facebook.com/${item.id}/picture?width=200&height=200`}
            />
            <div className="desc">
              <b>{item.name}</b><br />
            </div>
        </li>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Friends);
