import * as React from 'react';
import { ISlideProps } from './Slide.interface';
import style from '../Intro.module.less';
import { Image } from '../../Image/Image';

// tslint:disable-next-line: variable-name
export const Slide = (props: ISlideProps) => (
  <div className={`${style.slide} tcenter`}>
    <Image className={style.img} src={props.slide} />
    <h4 className={style.headline}>{props.header}</h4>
    <p className={style.content}>{props.content}</p>
</div>
);
