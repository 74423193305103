import { clone } from 'lodash';
import { LANDING_ACTIONS } from './Landing.action';

const defaultAppState: any = {
  isLoading: true,
  orders: [],
};

// tslint:disable-next-line: variable-name
export const Landing = (
  state = clone(defaultAppState),
  action: any,
) => {
  switch (action.type) {
    case LANDING_ACTIONS.GET_CURRENT_ORDERS:
      return {
        ...state,
        isLoading: true,
      };
    case LANDING_ACTIONS.FAIL.GET_CURRENT_ORDERS:
      return {
        ...defaultAppState,
        isLoading: false,
      };
    case LANDING_ACTIONS.SUCCESS.GET_CURRENT_ORDERS:
      return {
        ...state,
        isLoading: false,
        orders: action.payload.data.data,
      };
    default:
      return state;
  }
};
